input[type="radio"] {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  outline: none;
  border: 1px solid #2ccae8;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 11px;
  height: 11px;
  margin: 1px;
  border-radius: 100%;
}

input[type="radio"]:checked:before {
  background: #2ccae8;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  /* border-radius: 100%; */
  outline: none;
  border: 1px solid #2ccae8;
}
@media screen and (max-width: 992px) {
  input[type="checkbox"] {
    margin-left: 5px;
  }
}
input[type="checkbox"]:after {
  content: "";
  display: block;
  width: 11px;
  height: 11px;
  margin: 1px;
}
input[type="checkbox"]:checked {
  background: #2ccae8;
}
