/* img {
    width: 300px;
} */

/* button {
    border: none;
    color: white;
    padding: 14px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 2px;
    cursor: pointer;
    background-color: #424242;
} */

/* input {
    width: 30%;
    padding: 6px;
    border: 1px solid #cbcbcb;
    outline: none;
} */

p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.chat-page-wrapper {
  max-width: 575px;
  margin: auto;
}

.chat-page-wrapper .headerTitle img.innerLogo {
  display: block;
  z-index: 100;
}

.container {
  height: 100%;
  background: white;
  display: flex;
}

.channel-type {
  padding: 30px 10px;
  color: #2ccae8;
  display: flex;
  justify-content: space-between;
}

.channel-type h1 {
  margin: 5px 0;
}

.channel-type h1 {
  position: relative;
  text-align: center;
  display: block;
  width: 100%;
  visibility: hidden;
  height: 0;
  line-height: 0;
}

.channel-type h1::after {
  content: "Chats";
  display: block;
  visibility: visible;
  height: 100%;
  line-height: 1;
}

.no-channel-text {
  text-align: center;
  height: 0;
  line-height: 0;
  visibility: hidden;
}

.no-channel-text::after {
  content: "No chats found";
  display: block;
  text-align: center;
  visibility: visible;
  line-height: 1;
}
.loading-text {
  margin: auto;
  font-weight: 700;
}
.channel-type svg {
  margin: auto 0;
  animation: rotateIn 0.5s ease-in-out;
}

.channel-list.hide .channel-type svg {
  transform: rotate(180deg);
  animation: rotateOut 0.5s ease-in-out;
}

@keyframes rotateOut {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes rotateIn {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.group-card {
  width: fit-content;
  padding: 15px;
  margin-top: 20px;
  border: 1px solid white;
  border-radius: 10px;
}

.chat-wrapper {
  position: relative;
}

.card {
  width: fit-content;
  padding: 15px;
  margin-left: 60px;
  border: 1px solid black;
  border-radius: 10px;
}

.card-name {
  font-weight: bold;
}

.channel-list {
  background: white;
  flex: 1;
  border-right: 1px solid #cbcbcb;
  overflow-y: auto;
  width: 375px;
  position: absolute;
  height: calc(100vh - 76px);
  z-index: 9999;
  animation: slideOut 0.5s linear;
}

.channel-list.hide {
  margin-left: -340px;
  animation: slideIn 0.5s linear;
}

.no-channel {
  height: calc(100vh - 158px);
  display: flex;
}

.no-channel-text {
  margin: auto;
  font-weight: 700;
}

@keyframes slideIn {
  0% {
    margin-left: 0px;
  }

  100% {
    margin-left: -340px;
  }
}

@keyframes slideOut {
  0% {
    margin-left: -340px;
  }

  100% {
    margin-left: 0px;
  }
}

.channel-list.hide .channel-type {
  justify-content: end;
  height: 58px;
}

.channel-list.hide h1 {
  display: none;
}

.channel-list.hide .channel-wrapper {
  display: none;
}

.channel-list-item {
  background: #fcfcfc;
  padding: 12px;
  border-top: 1px solid #ececec;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 42px;
}

.channel-list-item:hover {
  background: #f0f0f0;
}

.channel-list-item:hover .channel-icon {
  visibility: visible;
}

.sort-channels {
  width: 100%;
  font-size: 16px;
  padding: 7px;
  cursor: pointer;
}

.unarchive {
  transform: rotate(180deg);
}

.archived-channel {
  padding: 10px;
}

.last-message {
  font-weight: 300;
}

.channel-list-item-name {
  font-weight: 600;
  cursor: pointer;
  overflow: hidden;
  max-width: 75ch;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}

.channel-icon {
  visibility: hidden;
  width: 20px;
}

.channel {
  background: white;
  flex: 3;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-bottom: 102px;
  margin-left: 60px;
  height: calc(100vh - 178px);
}

.channel-fixed-header {
  margin-top: 160px;
}

.thread {
  max-width: 35vw;
}

.thread,
.thread-input {
  border-left: 1px solid lightgray;
}

.underline {
  width: 100%;
  border-bottom: 1px solid lightgray;
}

.threads {
  max-width: 35vw;
}

.threads,
.threads-input {
  border-left: 1px solid lightgray;
}

.underline {
  width: 100%;
  border-bottom: 1px solid lightgray;
}

.channel-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leave-channel {
  margin-left: 12px;
  padding: 8px;
}

.freeze-channel {
  margin-right: 12px;
}

.freeze-channel input {
  width: auto;
}

.message-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10%;
}

.message-item {
  margin: 12px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.chat-message.message-from-you {
  margin-left: auto;
  background: #2ccae8;
  flex-direction: row;
  margin-right: 12px;
}

.message-item.message-from-you {
  flex-direction: row;
}

.oc-message,
.oc-message-og-tags {
  padding: 12px;
  display: flex;
}

.oc-message-og-tags {
  flex-direction: column;
  max-width: 50%;
  padding: 5px;
  margin-left: 7vw;
  margin-top: -10px;
  box-sizing: border-box;
  border: 1px solid black;
}

.oc-message,
.message-og-tags {
  padding: 12px 0px;
  display: flex;
}

.message-og-tags {
  flex-direction: column;
  padding: 5px;
  box-sizing: border-box;
}

.og-tags-url {
  margin: 0 0 5px;
}

.og-tags-title {
  margin: 0 0 10px;
}

.og-tags-description {
  margin: 0 0 5px;
}

.og-tags-img,
.show-users-status-btn,
.members-invite-btn {
  width: 100%;
}

.oc-admin-message {
  background: rgba(255, 0, 0, 0.05);
}

.report-notification {
  background: rgba(0, 255, 0, 0.5);
  color: white;
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin: 5px 0;
  padding: 5px;
}

.chat-message {
  border-radius: 8px;
  width: 70%;
  background: #4c4c4c;
  color: white;
  padding: 10px 5px 10px 10px;
  margin-left: 12px;
}

.admin-message {
  background: rgba(255, 0, 0, 0.05);
  width: 100%;
  color: black;
  margin-left: 0;
}

.message-info {
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  line-height: 24px;
  height: 25px;
}

.message-icon {
  visibility: hidden;
  width: 14px;
  filter: brightness(0) invert(1);
}

.oc-message-icon,
.oc-channel-list-icon {
  visibility: hidden;
  width: 14px;
}

.message:hover .message-icon,
.channel-list-item:hover .oc-channel-list-icon,
.member-item:hover .message-icon,
.double_tick-icon,
.member-item-wrapper .message-icon {
  visibility: visible;
}

.double_tick-icon-read {
  filter: none;
}

.mute-button {
  margin-top: 3px;
}

.member-item .message-icon {
  filter: none;
}

.oc-document-message,
.message-user-info,
.react-button-wrapper,
.reactions-list {
  display: flex;
}

.reactions-list {
  list-style: none;
  margin: 0;
  padding-left: 0;
  background: white;
  border-radius: 8px;
}

.reactions-list li {
  margin-right: 2px;
  padding: 4px;
}

.react-button-wrapper {
  margin: 5px 5px 0 0;
  width: 100%;
  justify-content: flex-end;
}

.react-button-img {
  width: 20px;
  filter: none;
}

.reactions {
  margin: 5px 0 0;
}

.reactions-item {
  cursor: pointer;
  margin-right: 5px;
}

.reactions-item-inner {
  font-size: 10px;
}

.oc-document-message-icon {
  width: 24px;
}

.oc-message:hover .oc-message-icon,
.message-input .oc-message-icon {
  visibility: visible;
}

.oc-message-sender-name {
  color: #6210cc;
  margin-left: 4px;
  font-weight: 600;
  margin-right: 4px;
}

.message-sender-name {
  font-weight: 600;
  margin-right: 4px;
  overflow: hidden;
}

.chat-wrapper {
  margin-top: 10px;
}

.channel-header {
  padding: 2px;
  font-size: 18px;
  font-weight: 800;
  position: fixed;
  background: #fff;
  width: 100%;
  z-index: 99;
}

.channel-header-wrapper {
  position: fixed;
  width: 100%;
  top: 0;
}

.members-list {
  border-left: 1px solid #cbcbcb;
  padding: 12px;
}

.member-item {
  padding: 8px 0px 0px 0px;
  cursor: pointer;
  font-weight: 600;
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.member-item-wrapper {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}

.member-item:hover {
  background: #f0f0f0;
}

.member-item-name {
  margin-left: 12px;
}

.member-list-btn {
  margin: 0 0 5px;
}

.member-selected {
  color: #62eeab;
}

.operators-list {
  padding: 20px 0;
  font-weight: bold;
}

.operator-item {
  padding: 8px 0px 0px 0px;
  cursor: pointer;
  font-weight: 600;
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.operator-item:hover {
  background: #f0f0f0;
}

.message-input {
  position: fixed;
  bottom: 0px;
  display: flex;
  border-top: 1px solid #cbcbcb;
  width: 100%;
  padding: 12px;
  background: white;
}

.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #ecececb0;
  z-index: 1;
}

.overlay-content {
  overflow-y: scroll;
  max-height: 400px;
  padding: 80px;
  opacity: 1;
  background: white;
  border: 1px solid #cbcbcb;
  width: 40%;
}

.custom-type-messages {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.custom-type-messages-content {
  overflow-y: scroll;
  max-height: 400px;
  padding: 20px;
  opacity: 1;
  background: white;
  border: 1px solid #cbcbcb;
  width: 40%;
}

.custom-type-button {
  margin: 12px 0 0 12px;
}

.form-input {
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 18px;
  width: 100%;
  border-radius: 2px;
}

.form-button {
  margin-right: 4px;
}

.user-submit-button {
  background-color: #6210cc;
}

.channel-create-button {
  background-color: #62eeab;
  width: 100%;
}

.select-custom-type {
  padding: 12px;
}

.select-custom-type select {
  width: 100%;
  font-size: 16px;
  padding-top: 12px;
  outline: none;
  border: none;
  cursor: pointer;
}

.send-message-button {
  border-radius: 0px 4px 4px 0px;
  margin-right: 8px;
}

.control-button {
  background: inherit;
  padding: 4px;
  margin-right: 1px;
}

.error {
  background: rgb(26, 22, 22);
  display: flex;
  padding: 24px;
  width: 100%;
}

.file-upload-label,
.user-profile-image-upload-label,
.number-of-undelivered-members-btn {
  cursor: pointer;
}

.file-upload-label img {
  width: 46px;
  vertical-align: middle;
}

.message-type-add {
  cursor: pointer;
  padding: 12px;
}

.message-type-label {
  margin: 0 10px;
}

.message-type-select {
  font-size: 16px;
  padding: 7px;
  outline: none;
  cursor: pointer;
}

.profile-image-fallback {
  width: 50px;
  background: #e9e9e9;
  height: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  object-fit: cover;
}

.typing-indicator {
  position: fixed;
  bottom: 80px;
  background-color: #fff;
  opacity: 0.8;
  margin-left: 20px;
  margin-top: 20px;
  font-weight: 500;
}

.display-none {
  display: none;
}

.message-input-column {
  flex-direction: column;
}

.freeze-notification {
  position: sticky;
  top: 0;
  width: 100%;
  line-height: 30px;
  opacity: 70%;
  background-color: rgb(173, 201, 255);
  text-align: center;
}

.frozen-icon {
  width: 20px;
  margin-right: 10px;
}

.underline {
  width: 100%;
  border-bottom: 1px solid lightgray;
}

.user-copied-message {
  position: fixed;
  right: 20px;
}

.image-size-error {
  width: 100%;
  background: rgba(255, 0, 0, 0.2);
  padding: 5px;
  margin: 5px 0;
  text-align: center;
}

.choise-report-obj {
  cursor: pointer;
  color: #6210cc;
  text-decoration: underline;
}

.number-of-undelivered-members {
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
}

.number-of-undelivered-message-btn:hover::after {
  content: attr(data-title);
  position: absolute;
  border: 1px solid gray;
  background-color: darkgray;
  border-radius: 5px;
  padding: 2px 4px;
  font-size: 10px;
}

.banned-muted-users-list {
  margin-top: 20px;
}

.show-users-status-btn {
  display: block;
  margin-top: 5px;
}

.user-online-status {
  margin: 0 0 0 5px;
}

.user-profile-title {
  margin-top: 0;
}

.user-profile-image-wrapper,
.user-profile-nickname-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.user-profile-nickname-wrapper {
  margin: 0 0 10px;
}

.user-profile-image {
  border-radius: 50%;
  padding: 5px;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.user-profile-nickname-input {
  width: auto;
}

.user-profile-nickname-button {
  border: none;
  background: transparent;
  color: black;
}

.user-profile-image-upload-label,
.user-profile-nickname-button {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  padding: 5px;
}

.number-of-undelivered-members {
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
}

.number-of-undelivered-message-btn:hover::after {
  content: attr(data-title);
  position: absolute;
  border: 1px solid gray;
  background-color: darkgray;
  border-radius: 5px;
  padding: 2px 4px;
  font-size: 10px;
}

.do-not-disturb-wrapper,
.do-not-disturb-start-wrapper,
.do-not-disturb-end-wrapper,
.notifications-snooze-wrapper,
.notifications-snooze-input-wrapper {
  display: flex;
}

.do-not-disturb-wrapper,
.notifications-snooze-wrapper {
  flex-direction: column;
}

.do-not-disturb-wrapper,
.do-not-disturb-start-wrapper,
.do-not-disturb-end-wrapper,
.notifications-snooze-wrapper,
.notifications-snooze-input-wrapper {
  margin-bottom: 10px;
}

.notifications-overlay-content {
  padding: 40px;
  width: 50%;
  max-height: 500px;
}

.notification-modal-label,
.notification-modal-input {
  margin-right: 10px;
}

.notification-modal-input {
  width: 40%;
}

.message-input-buttons {
  display: flex;
}

.message-input-buttons svg,
label {
  margin: auto 0px;
}

.message-input-buttons label {
  padding-top: 8px;
}

.message-input {
  flex: 0 0 100%;
  width: 78%;
}

.message-input input {
  width: inherit;
  margin-right: 10px;
  height: 30px;
  border-radius: 10px;
  padding: 6px;
  border: 1px solid #cbcbcb;
  outline: none;
}

.option-wrapper svg {
  color: white;
  height: 16px;
  width: 16px;
}

.option-wrapper button {
  padding: 0px;
  margin-top: -5px;
  margin-right: 5px;
}

.chat-message img {
  width: inherit;
}

.chat-message svg {
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .chat-page-wrapper {
    max-width: inherit;
  }

  .chat-wrapper {
    display: flex;
  }

  .channel-list {
    position: inherit;
  }

  .channel-type svg {
    display: none;
  }

  .channel {
    margin-left: 10px;
  }

  .message-input {
    width: 78%;
  }

  .channel-list.hide {
    margin-left: 0px;
  }

  .channel-list.hide .channel-type {
    justify-content: start;
  }

  .channel-list.hide .channel-type h1 {
    display: block;
  }

  .channel-list.hide .channel-wrapper {
    display: block;
  }
}

@media only screen and (max-width: 390px) {
  .chat-page-wrapper .headerTitle img.innerLogo {
    width: 23px !important;
    height: 23px !important;
    top: -4px !important;
    z-index: 100;
  }
}
