@import "~bootstrap/scss/bootstrap";

#root {
  padding: 0 !important;
}

.choose-language {
  left: auto !important;
  right: 50px !important;
  top: 80px !important;
  width: 100%;
  &.centered {
    left: auto !important;
    right: auto !important;
    top: 0;
    padding: 10px;
    .language-container {
      left: 0;
      right: 0;
      margin: auto;
      @media screen and (max-width: 500px) {
        margin: auto 5%;
      }
    }
  }

  .language-container {
    background-color: #ffffff;
    border-radius: 30px;
    padding: 30px;
    max-width: 500px;
    min-width: 280px;
    position: absolute;
    right: 0;

    .lang-head {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      /* identical to box height */
      color: #000000;
    }

    .languages {
      display: flex;
      flex-wrap: wrap;

      .language-btn {
        text-align: center;
        cursor: pointer;
        padding: 10px 0px;
        border: 1px solid #e1e0e0;
        border-radius: 15px;
        margin: 7.5px;
        font-size: 14px;
        flex-basis: 45%;
        flex-grow: 1;

        &.selected,
        &:hover {
          color: #2ccae8;
          border-color: #2ccae8;
        }
      }
    }

    .save {
      width: 100%;
      margin-top: 70px;

      .save-changes {
        text-transform: uppercase;
        margin: 0px 7.5px;
        background: #2ccae8;
        border-radius: 15px;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        width: 100%;
        height: 42px;
        border: none;
        outline: none;
      }
    }
  }

  @media screen and (max-width: 1090px) {
    .language-container {
      .language-btn {
        width: 100% !important;
      }
    }
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    top: 115px !important;
    left: 0 !important;
    right: 0 !important;

    .language-container {
      margin: 25px;

      .lang-head {
        font-size: 16px;
      }

      .languages {
        .language-btn {
          width: 100%;
        }
      }
    }
  }

  @media screen and (min-width: 1400px) {
    .language-container {
      right: calc((100vw - 1400px) / 2);
    }
  }
}

.verify-phone {
  display: flex;
  align-items: center;
  justify-content: center;

  .phone-container {
    background-color: #ffffff;
    border-radius: 30px;
    padding: 30px;
    max-width: 500px;
    min-width: 280px;
    right: 0;

    .phone-head {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      /* identical to box height */
      color: #000000;
    }

    .phones {
      display: flex;
      flex-wrap: wrap;

      .phone-btn {
        text-align: center;
        cursor: pointer;
        padding: 10px 0px;
        border: 1px solid #e1e0e0;
        border-radius: 15px;
        margin: 7.5px;
        font-size: 14px;
        flex-basis: 45%;
        flex-grow: 1;

        &.selected,
        &:hover {
          color: #2ccae8;
          border-color: #2ccae8;
        }
      }
    }

    .save {
      width: 100%;
      margin-top: 70px;

      .save-changes {
        text-transform: uppercase;
        margin: 0px 7.5px;
        background: #2ccae8;
        border-radius: 15px;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        width: 100%;
        height: 42px;
        border: none;
        outline: none;
      }

      .rqst-otp {
        text-transform: uppercase;
        margin: 0px 7.5px;
        border: 1px solid #2ccae8 !important;
        background: #fff;
        border-radius: 15px;
        font-weight: 500;
        font-size: 14px;
        color: #2ccae8;
        width: 100%;
        height: 42px;
        border: none;
        outline: none;
      }
    }
  }

  @media screen and (max-width: 1090px) {
    .phone-container {
      .phone-btn {
        width: 100% !important;
      }
    }
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    top: 115px !important;
    left: 0 !important;
    right: 0 !important;

    .phone-container {
      margin: 25px;

      .phone-head {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
      }

      .phones {
        .phone-btn {
          width: 100%;
        }
      }
    }
  }
}

.header-container {
  max-width: 1400px;
  margin: 0 auto !important;
  font-family: "Poppins";
  border-bottom: 1px solid rgba(44, 202, 232, 0.25);
  height: 90px;

  .header-left {
    min-width: 40%;
    max-width: 50%;

    .search-container {
      position: relative;
      min-width: 300px;

      input {
        height: 44px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 50px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #858585;
        padding: 0px 50px 0px 20px;

        &::placeholder {
          font-family: "Poppins";
          font-style: normal;
          font-size: 14px;
        }
      }

      .search-icon {
        position: absolute;
        right: 4px;
        top: 4px;
        cursor: pointer;

        @media screen and (max-width: 320px) {
          width: 75px;
        }
      }
    }
  }

  .header-right {
    min-width: 45%;
    max-width: 50%;
    display: flex;
    justify-content: space-evenly;

    .switch {
      cursor: pointer;
      display: flex;
      width: fit-content;
      align-items: center;
      padding: 10px 30px;
      height: 44px;
      background: rgba(44, 202, 232, 0.1);
      border-radius: 50px;
      font-size: 14px;

      @media screen and (max-width: 1080px) {
        font-size: 12px;
      }

      @media screen and (max-width: 992px) {
        height: 37px;
        font-size: 10px;
        padding: 0px 15px;
      }
    }

    .language {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      height: 44px;
      background: rgba(245, 245, 245, 0.2);
      border: 1px solid #e5e5e5;
      border-radius: 50px;
      font-size: 14px;
      cursor: pointer;

      img {
        margin-right: 12px;
      }
    }

    .profile {
      height: 44px;
      border: 1px solid #e5e5e5;
      border-radius: 50px;
      display: flex;
      align-items: center;
      width: fit-content;
      padding: 4px 4px 4px 18px;

      .profile-img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        margin-left: 14px;
        cursor: pointer;
        object-fit: cover;
      }

      img {
        cursor: pointer;
      }
    }

    @media screen and (max-width: 992px) {
      max-width: 100%;

      .language {
        padding: 10px;

        img {
          margin-right: 0;
        }
      }
    }
  }

  .mobile-header {
    height: 100%;
    align-items: center;

    @media screen and (max-width: 380px) {
      .any-logo {
        width: 75px;
      }

      .switch {
        padding: 0px 8px;
      }

      .language {
        display: flex;
        align-items: center;
        padding: 5px;
        height: 35px;

        .flag {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

.verify-mobile-modal {
  border-radius: 15px;
  padding: 16px;

  .verify-head {
    font-size: 24px;
    font-weight: 600;
    font-family: "Poppins";
    margin: 20px 0px;
  }

  .verify-text {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
  }

  .req-btn {
    background-color: transparent;
    border: 1px solid #2ccae8;
    color: #2ccae8;
    width: 100%;
    height: 46px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 10px;
  }

  .verify-btn {
    background: #2ccae8;
    border: none;
    color: #ffffff;
    width: 240px;
    height: 46px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 10px;
  }

  .verify-btn:disabled,
  .req-btn:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .PhoneInput {
    border-radius: 10px;
    border: 1px solid #858585;
    background: #fff;
    font-size: 14px;
    font-family: "Poppins";
    color: #000;
    gap: 5px;
    grid-gap: 5px;

    input,
    select {
      border: none;
      border-radius: 10px;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      /* Add a custom background image for the arrow */
      background-image: url("./../../_assets/images/home-images/selectArrow.svg");
      background-repeat: no-repeat;
      background-position: right center;
      width: 70px;
      margin-left: 10px;
      display: flex;
      align-items: center;
    }
  }

  .enter-otp,
  .timer {
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 400;
  }

  .otp-input {
    border-radius: 10px;
    border: 1px solid #858585;
    background: #fff;
    padding-left: 10px;
  }
}

.location-input {
  border: 1px solid #858585;
  border-radius: 10px;
  padding: 0px 20px;
  font-size: 14px;
  font-family: "Poppins";
}

.location-input::placeholder {
  font-size: 14px;
  font-family: "Poppins";
  font-style: normal;
}

.location-dropdown {
  top: -31px;
  border: 1px solid #858585;
  border-radius: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: 11;
  color: #858585;
  font-size: 14px !important;
  font-family: "Poppins" !important;
  overflow-y: scroll;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-height: 100%;

  .places-item {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    background-color: #fff;
    cursor: pointer;

    img {
      background-color: rgb(235, 235, 235) !important;
      border-radius: 12px !important;
      min-width: 40px !important;
      height: 40px !important;
      margin-right: 16px !important;
      padding: 8px;
    }

    &:hover {
      background-color: rgb(244, 244, 244);
    }
  }
}

.location-dropdown::-webkit-scrollbar {
  display: none;
}

.selected-location {
  height: 36px;
  min-width: 120px;
  border: 1px solid #2ccae8;
  border-radius: 10px;
  font-size: 14px;
  font-family: "Poppins";
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 18px;
  margin-right: 10px;
  margin-top: 20px;

  img {
    height: 25px;
    width: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}

.custom-header {
  border-bottom: 1px solid rgba(44, 202, 232, 0.25);
}

.reservations {
  padding: 20px;
  min-height: 50vh;

  .head {
    font-family: "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    text-align: left;
  }

  .tabs-container {
    display: flex;
    gap: 10px;

    .tab-item {
      width: 100px;
      height: 40px;
      border-radius: 100px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      p {
        text-align: center;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        margin: 0;

        &.active {
          color: #2ccae8;
        }
      }
    }
  }

  .tab-contents {
    .book,
    .upcoming {
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      text-align: left;
      margin: 0;
    }

    .book {
      text-transform: uppercase;
      color: #2ccae8;
      font-weight: 500;
    }
  }
}

.payments-received {
  .head {
    color: #000;
    font-family: "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }

  .tab-heads {
    display: flex;
    gap: 40px;

    p {
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      cursor: pointer;

      &.active {
        color: #2ccae8;
        border-bottom: 3px solid #2ccae8;
      }
    }
  }

  .saved {
    color: #2ccae8;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    text-align: left;
  }

  .payments-container {
    font-family: "Poppins";
    display: flex;
    flex-direction: column;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 30px 0px;

      .left-line,
      .right-line {
        background-color: #e5e5e5;
        height: 1px;
        width: 100%;
      }

      p {
        margin: 0px 5%;
        color: #2ccae8;
        text-align: center;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        width: 100%;
        display: inline;
      }
    }

    .payment {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      img {
        margin-right: 15px;
      }

      .label {
        font-weight: 400;
        display: flex;
        flex-direction: column;
        gap: 2px;

        .owner {
          color: #000;
          font-size: 14px;
          margin: 0;
        }

        .date {
          color: #858585;
          font-size: 12px;
          margin: 0;
        }
      }

      .amount {
        color: #000;
        text-align: right;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
      }
    }
  }

  .bank-data {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 500px;
    border-radius: 10px;
    border: 1px solid #858585;
    padding: 30px;

    .labels,
    .values {
      p {
        color: #858585;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        text-align: left;
      }
    }

    .values {
      p {
        color: #000;
      }
    }

    .alternate {
      p {
        margin: 0;

        &:nth-child(odd) {
          color: #858585;
        }

        &:nth-child(even) {
          color: #000;
        }
      }
    }
  }

  button {
    height: 46px;
    border-radius: 10px;
    background: #2ccae8;
    border: none;
    display: flex;
    align-items: center;
    color: #fff;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin-top: 40px;
    width: fit-content;
    padding: 0 15px;
  }
}

.categories {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  max-width: 1400px;
  margin: 0 auto !important;
}

.scroll-left-icon {
  position: absolute;
  left: 10px;
  cursor: pointer;
}

.scroll-right-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.categories-container {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  height: 100px;
  align-items: center;

  &::-webkit-scrollbar {
    display: none;
  }

  .category-item {
    margin: 0px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #000000 !important;
    cursor: pointer;

    &:hover span {
      border-bottom: 1.5px solid #000000;
      opacity: 1;
    }

    &:hover img {
      opacity: 1;
    }

    span {
      margin-top: 15px;
      font-weight: 400;
      font-size: 12px;
      font-family: "Poppins";
      white-space: nowrap;
      padding-bottom: 3px;
      opacity: 0.6;
      transition: opacity 200ms ease, transform 200ms ease;

      &.selected {
        border-bottom: 1.5px solid #000000;
        opacity: 1;
      }
    }

    img {
      height: 30px;
      width: 30px;
      opacity: 0.6;
      transition: opacity 200ms ease, transform 200ms ease;

      &.selected {
        opacity: 1;
      }
    }
  }
}

.contractors-container {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 250px;
  max-width: 1400px;
  margin: 0 auto !important;

  .contractor-item {
    padding: 0px 8px;
    height: 450px;

    .favourite-icon {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 10;
      cursor: pointer;
    }

    .contractor-info {
      font-family: "Poppins";
      font-style: normal;
      margin-top: 20px;

      // padding: 0px 10px;
      .first {
        display: flex;
        justify-content: space-between;
        width: 100%;

        p {
          margin-bottom: 5px;
        }

        img {
          margin-bottom: 3px;
        }

        .name {
          width: 70%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-weight: 500;
          font-size: 18px;
          line-height: 30px;
          color: #000000 !important;
          text-align: left;
        }

        .rating {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #000000 !important;
          text-align: end;
        }
      }

      .second {
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #858585;
          text-align: left;
          margin-bottom: 5px;
        }

        strong {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          text-decoration-line: underline;
          color: #000000;
        }
      }
    }

    @media screen and (max-width: 992px) {
      height: 435px;
    }

    @media screen and (max-width: 768px) and (min-width: 520px) {
      height: 570px;
    }
  }

  @media screen and (max-width: 992px) {
    padding-bottom: 50px;
  }
}

.mobile-bottom-nav {
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  height: 70px;
  padding: 0px 50px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 4px -4px 4px rgba(0, 0, 0, 0.15);
  z-index: 11;

  a {
    align-items: center;
    justify-content: center;

    img {
      height: 24px;
      width: 24px;
    }

    span {
      font-size: 12px;
      font-weight: 400;
      font-family: "Poppins";
      color: #000000;
    }
  }
}

.fixed-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto !important;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%);

  .show-map {
    background: #1e2124;
    border-radius: 52px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    padding: 14px 19px;
    z-index: 10;
    color: #ffffff;
    width: fit-content;
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    font-size: 14px;

    img {
      margin-right: 10px;
    }
  }

  @media screen and (max-width: 992px) {
    position: relative;

    .show-map {
      display: none;
    }
  }
}

.footer-container {
  font-family: "Poppins";
  display: flex;
  // flex-direction: column;
  // align-items: center;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
  position: relative;
  min-width: 100%;
  max-width: 1400px;
  margin: 0 auto !important;

  .logo-container {
    margin-top: 30px;
  }

  .links {
    margin-top: 20px;
    width: 100%;

    a {
      margin-right: 20px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
  }

  .seperator {
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    font-family: "Poppins";
  }

  @media screen and (min-width: 1400px) {
    min-width: auto;
  }

  @media screen and (max-width: 992px) {
    padding: 20px;

    .logo-container {
      margin-bottom: 20px;
    }

    .links {
      display: flex;
      flex-direction: column;

      a {
        margin-bottom: 18px !important;
      }
    }
  }
}

.rights {
  p {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    text-align: left;
    height: 40px;
    padding: 20px 0px 10px 20px;
    font-family: "Poppins";
    font-size: 16px;
  }
}

.pac-container {
  background-color: #fff !important;
  font-size: 16px !important;
  font-family: "Poppins" !important;
  color: #333 !important;
  top: 75px !important;
  border-radius: 20px !important;
}

.pac-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
}

.pac-icon {
  -webkit-box-pack: center !important;
  -webkit-box-align: center !important;
  font-size: 17px !important;
  background-color: rgb(235, 235, 235) !important;
  border-radius: 12px !important;
  min-width: 48px !important;
  height: 48px !important;
  margin-right: 16px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-image: url("../../_assets/images/other-images/location-icon.svg") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.places-dropdown {
  background-color: #fff !important;
  font-size: 14px !important;
  font-family: "Poppins" !important;
  color: #333 !important;
  border-radius: 20px !important;
  z-index: 11;
  padding: 10px 20px;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 8px 16px rgba(0, 0, 0, 0.15);
  margin-top: 10px;

  .places-item {
    padding: 10px 10px 10px 5px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 15px;
    cursor: pointer;

    img {
      background-color: rgb(235, 235, 235) !important;
      border-radius: 12px !important;
      min-width: 40px !important;
      height: 40px !important;
      margin-right: 16px !important;
      padding: 8px;
    }

    &:hover {
      background-color: rgb(244, 244, 244);
    }
  }
}

.search-container {
  font-family: "Poppins";

  input {
    &::placeholder {
      font-family: "Poppins" !important;
      font-style: normal;
    }
  }
}

.form-control::placeholder {
  font-style: normal !important;
}
