.headany
{

font-family: Poppins;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 24px;
color: #2CCAE8;
}
.txtTerms
{
    font-family: Poppins;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 30px;
color: #000000;

}
.subCate
{
    font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;

color: #000000;
}
