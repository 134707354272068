input {
    width: 100%;
    border: 0; 
    border-bottom: 0.5px solid #D8D8D8;
    border-color: #D8D8D8;
    
   
    font-size: 16px;
    font-style: italic;
  font-family: Poppins;
    line-height: 100%;
  }
  
  .label {
    font-family: Poppins;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 8px;
    font: Poppins;
    font-weight: 700;
    font-style: Bold;
  }
  
  .input-text {
    width: 308px;
   
  }
  
  input.input-text {
    border-bottom: 1px solid #ADADAD;
  }
  .input-text {
    color:black;
  }
  .input-text ::placeholder {
    color: #ADADAD;
  }  
  
  .input-text.is-not-valid input {
    border-bottom: 1px solid red;
    border-color: red;
  
    color: red;
  }
  .input-text.is-not-valid {
    color: red;
  }
  
  .input-text.is-not-valid ::placeholder {
    color: red;
  }
  
  
  
  
  .input-text.is-valid input {
    border-bottom: 1px solid green;
    border-color:green;
  
    color: green;
  }
  .input-text.is-valid {
    color: green;
  }
  
  .input-text.is-valid ::placeholder {
    color: green;
  } */
  
  
  
  
  
   .error-message {
    width: 105%;
    border: 0;
    border-bottom: 1px solid #fc0b0b;
    border-color: #fc0b0b;
    outline: 0;
    height: 32px;
    font-size: 90px;
  
    line-height: 16px;
  } 
  /* .error_message{
    color:red;
  } */