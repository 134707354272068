.portfolio-ctn {
  font-family: Poppins !important;
  margin: 20px 0 0 0;
  /* width: 800px; */
}

.top-bar {
  position: fixed;
  z-index: 11;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 40px 25px 10px 25px;
  background-color: rgba(255, 255, 255, 0);
  @media screen and (max-width: 992px) {
    top: -5px;
    z-index: 11;
    left: -1px;
    width: 102%;
  }
  &.isWhite {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }
  button {
    background: none;
    position: relative;
    border: none;
    outline: none;
    width: min-content;
    z-index: 10;
  }
  button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
.icon-container {
  button {
    background: none;
    border: none;
    outline: none;
    width: min-content;
    z-index: 10;
  }
  button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  right: 0px;
  @media screen and (max-width: 992px) {
  }
}

.porfolio-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 50px;
  max-width: 1400px;
  margin: 0 auto !important;
  .main-head {
    font-weight: 600;
    font-size: 28px;
    color: #000000;
    font-family: "Poppins";
    padding: 20px 0px 10px 0;
    max-width: 75%;
    @media screen and (max-width: 992px) {
      max-width: 100%;
    }
  }
  @media screen and (max-width: 992px) {
    padding: 0px;
    .main-head {
      font-size: 18px;
      padding: 20px;
    }
  }
}

#root {
  padding: 20px 0;
  @media screen and (max-width: 992px) {
    padding: 0 !important;
  }
}

.MainFooter {
  width: 100%;
  left: 0;
}

.header-wrapper {
  border-bottom: 1px solid #e1e0e0;
  padding: 0px 50px !important;
  .headerMain {
    max-width: 100% !important;
    width: 100% !important;
  }
  table {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .headerTitle {
    font-size: 23px !important;
    padding-left: 0px !important;
  }
  .signup_text {
    font-size: 18px !important;
  }
  @media screen and (max-width: 992px) {
    display: none;
    min-height: 50px;
    padding: 0px 20px !important;
    .signup_text {
      display: none !important;
    }
    .pc-img {
      display: none !important;
    }

    .mobile_menu .pc-img {
      display: inline !important;
    }
    img.innerLogo {
      top: -20px !important;
    }
  }
  @media screen and (max-width: 350px) {
    .mL10.app-img {
      display: none !important;
    }
  }
}

.protection-container {
  background-color: #ffffff;
  position: relative;
  width: 80%;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  border-radius: 30px;
  margin-top: 100px;
  margin-bottom: 100px;
  max-width: 1400px;
  padding: 0px 20px;
  &::-webkit-scrollbar {
    display: block;
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-right: none;
    border-left: none;
    border-radius: 15px;
  }

  &::-webkit-scrollbar-track-piece:end {
    background-color: transparent;
    margin-bottom: 20px;
  }

  &::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 20px;
  }
  .close-btn {
    position: absolute;
    right: 30px;
    top: 20px;
    img {
      cursor: pointer;
    }
  }
  .protection-inner {
    padding: 20px;
    margin-top: 30px;
    @media screen and (max-width: 992px) {
      width: 100%;
      padding: 10px;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .protection-head {
    font-family: "Poppins";
    font-style: normal;
    text-align: center;
    margin-bottom: 40px;
    .main-head {
      font-size: 38px;
      font-weight: 700;
      span {
        color: #2ccae8;
      }
    }
    .sub-head {
      font-weight: 400;
      font-size: 14px;
    }
  }
  .protection-body {
    h6 {
      font-weight: 600;
      font-size: 18px;
    }
    p {
      margin-top: 10px;
      margin-bottom: 30px;
      font-weight: 400;
      font-size: 14px;
      text-align: left;
    }
  }
  @media screen and (max-width: 992px) {
    width: 95%;
    padding: 10px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.loading {
  position: relative !important;
  margin-bottom: 100px !important;
}

.wrapper {
  width: 50%;
  position: relative;
  @media screen and (max-width: 992px) {
    width: 100%;
    position: sticky;
    bottom: 0;
  }
}

.select-card-outer {
  width: 50%;
  @media screen and (max-width: 992px) {
    width: 100%;
  }
}
.select-contractor-mobile {
  position: relative !important;
  display: flex;
  flex-direction: column;
  width: 50%;
  border: 1px solid #eaeaea;
  border-radius: 30px;
  height: fit-content;
  position: fixed;
  bottom: 0;
  /* flex: 0 0 50%; */
  font-family: "Poppins";
  .date {
    border: 1px solid #e1e0e0;
    border-radius: 15px;
    width: 50%;
    display: flex;
    align-items: center;
    height: 48px;
    margin-right: 10px;
    img {
      margin-left: 20px;
    }
    span {
      margin-left: 20px;
    }
  }
  .time {
    height: 48px;
    width: 50%;
    border: 1px solid #e1e0e0;
    border-radius: 15px;
    display: flex;
    margin-left: 10px;
    align-items: center;
    img {
      height: 42px;
      width: 42px;
      margin-left: 10px;
    }
    p {
      margin-bottom: 0;
      font-size: 14px;
      margin-left: 10px;
    }
    small {
      font-weight: 400;
      font-size: 10px;
      color: #858585;
      margin-left: 10px;
    }
  }
  .select-btn {
    background: #2ccae8;
    border-radius: 15px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    height: 48px;
    margin-top: 20px;
    color: #fff;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 16px;
  }
  .chat-btn {
    border-radius: 15px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    background: #ffffff;
    border: 1.5px solid #000000;
    border-radius: 15px;
    margin-top: 20px;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 16px;
    img {
      margin-right: 10px;
    }
  }
  @media screen and (max-width: 992px) {
    width: 100%;
    padding: 0px;
    background-color: #fff;
    z-index: 10;
    position: fixed !important;
    border-radius: 0;
    bottom: 0;
    // top: 50px !important;
    .date {
      font-weight: 400;
      font-size: 12px;
      margin-right: 1px;
    }
    .time {
      font-size: 12px;
      p {
        font-size: 12px;
      }
      img {
        height: 22px;
        width: 22px;
      }
    }
    .select-btn,
    .chat-btn {
      height: 40px;
      border-radius: 10px;
      font-size: 14px;
      img {
        height: 20px;
        width: 20px;
      }
    }
    .contractor-rating {
      border: 1px solid #e5e5e5;
      border-radius: 50px;
    }
  }
  @media screen and (max-width: 300px) {
    padding: 10px;
    border-radius: 10px;
    .date,
    .time,
    .time p {
      font-size: 10px;
    }
  }
}

.local-bootstrap {
  @import "~bootstrap/scss/bootstrap";

  /* .porfolio-container {
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  &.carrosel-ctn {
    display: flex;
    justify-content: center;
    height: 100%;
    /* object-fit: cover; */
    position: relative;
    z-index: -1;
  }

  &.carrousels {
    max-width: 760px;
    width: 100%;
    max-height: 700px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none !important;
    @media screen and (max-width: 992px) {
      padding: 10px;
    }
  }

  &.carrousels img {
    max-height: 900px;
  }

  &.carrousels .custom-slider-img,
  &.carrousels .carousel {
    height: 50vh;
    object-fit: cover;
    @media screen and (min-width: 992px) {
      height: 70vh;
    }
  }

  &.carrousels .bear-react-carousel__root {
    height: 500px !important;
  }

  /* .carrousels #bear-react-carousel_fed99e38-59e0-4860-a486-f8005b0ebf49 {
    height: 500px;
  } */

  &.carrousels .bear-react-carousel__content {
  }
  &.carrousels .bear-react-carousel__nav-group {
    position: relative;
  }

  &.carrousels .bear-react-carousel__slide-item__div {
    background-position: center;
    width: 100%;
  }

  .filled-star {
    filter: invert(72%) sepia(33%) saturate(1104%) hue-rotate(150deg)
      brightness(92%) contrast(98%);
  }

  .hide-labels a[target="_blank"] {
    display: none;
  }

  .info-text {
    position: absolute;
    background-color: transparent;
    color: #2cc9e8;
    font-size: 12px;
    padding: 5px 10px;
    top: 105%;
    right: 0;
    font-weight: 600;
    width: max-content;
    @media screen and (max-width: 992px) {
      left: 0;
      right: 0;
      top: 100%;
    }
  }
  .object-fill {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .s-img-sub {
    height: 220px;
    @media screen and (min-width: 1400px) {
      height: 260px;
    }
  }

  .primary-img {
    height: 440px;
    border-radius: 30px;
    width: 100%;
    @media screen and (min-width: 1400px) {
      height: 520px;
    }
  }

  .secondary-img {
    width: 100%;
    height: 220px;
    border-radius: 30px;
    @media screen and (min-width: 1400px) {
      height: 260px;
    }
  }
  .portfolio-sub-head {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    color: #000000;
    display: flex;
    align-items: center;
  }

  .view-more {
    position: absolute;
    bottom: 20px;
    right: 30px;
    // width: 153px;
    height: 40px;
    padding: 10px 20px;
    background: rgba(32, 32, 32, 0.65);
    border-radius: 10px;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    cursor: pointer;
    @media screen and (max-width: 900px) {
      width: 115px;
    }
  }
  .available-img {
    width: 124px;
    height: 115px;
    border-radius: 8px;
    margin: 0 0 15px 9px;
  }

  .contractor-container {
    display: flex;
    padding-bottom: 30px;
    margin-bottom: 30px;
    margin-top: 30px;
    @media screen and (max-width: 992px) {
      margin-top: 10px;
    }
  }

  .contractor-detail-card {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    font-family: "Poppins";
    margin-right: 30px;
    @media screen and (max-width: 992px) {
      width: 100%;
      margin-right: 0;
      min-width: 100%;
    }
  }

  .details-card-primary {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 30px;
  }

  .details-card-secondary {
    border-bottom: 1px solid #e5e5e5;
    padding: 30px 30px 30px 0px;
    span {
      color: #2ccae8;
    }
    p {
      span {
        color: #2ccae8;
        text-decoration: none;
        cursor: pointer;
      }
      span:hover {
        text-decoration: underline;
      }
    }
    @media screen and (max-width: 992px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .details-card-secondary p {
    font-size: 14px;
    color: #858585;
    text-align: left;
  }

  .contractor-info {
    font-family: "Poppins";
    font-style: normal;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e5e5e5;
    // padding: 0px 10px;
    .first {
      display: flex;
      justify-content: space-between;
      width: 100%;
      p {
        margin-bottom: 5px;
      }
      img {
        margin-bottom: 3px;
      }
      .name {
        width: 70%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: #000000 !important;
        text-align: left;
      }
      .rating {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #000000 !important;
        text-align: end;
      }
    }
    .second {
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #858585;
        text-align: left;
        margin-bottom: 5px;
      }
      strong {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #000000;
      }
    }
    @media screen and (max-width: 992px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .details-card-left {
    width: 60%;
    .contractor-rating {
      font-size: 12px;
      font-weight: 400;
      color: #222222;
    }
    .contractor-infos {
      max-width: 70%;
    }
    .contractor-name {
      font-size: 22px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
    .verified {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #2ccae8;
      margin-bottom: 0;
    }
  }

  .contractor-image-container {
    width: 96px;
    height: 96px;
    position: relative;
  }
  .chat-btn {
    border-radius: 15px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    background: #ffffff;
    border: 1.5px solid #000000;
    border-radius: 15px;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 16px;
    img {
      margin-right: 10px;
    }
  }
  .details-card-mobile {
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    @media screen and (max-width: 992px) {
      border: none;
      padding-bottom: 30px;
      border-radius: 0;
      border-bottom: 1px solid #e5e5e5;
      margin-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
      .verified-btn {
        background: #2ccae8;
        border: 1px solid #2ccae8;
        border-radius: 50px;
        font-weight: 400;
        font-size: 10px;
        height: 24px;
        padding-bottom: 0;
        margin-top: 0;
        align-items: center;
        width: 107px;
      }
      .verified {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #2ccae8;
        margin-bottom: 0;
        margin-left: 20px;
      }
      .contractor-image-container {
        width: 76px;
        height: 76px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .name {
        font-size: 16px;
        margin-bottom: 10px;
        text-align: left;
      }
      .exp {
        font-size: 14px;
        text-align: left;
        span {
          color: #2cc9e8;
        }
      }
      .rating {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin: 0;
        border: 1px solid #e5e5e5;
        border-radius: 50px;
        height: 24px;
        justify-content: center;
        padding: 0px 5px;
      }
    }
  }
  .contractor-image-container .contractor-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  .details-card-right {
    width: 40%;
    margin-top: 5px;
    p {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .w-5 {
    width: 50px;
    color: #2ccae8;
    font-size: 20px;
    font-weight: 700;
    font-family: "Poppins";
  }
  .checked-image {
    position: absolute;
    bottom: -10px;
    right: -5px;
  }

  .d-flex {
    display: flex;
  }

  .verified-btn {
    display: flex;
    align-items: flex-end;
    padding-bottom: 10px;
    justify-content: space-evenly;
    background: #2ccae8;
    border-radius: 5px;
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    // padding: 8px 37px;
    margin-top: 20px;
    height: 40px;
    text-align: center;
    font-family: "Poppins";
    border: none;
    width: 100%;
  }

  .select-contractor {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #eaeaea;
    border-radius: 30px;
    // padding: 30px;
    height: fit-content;
    max-height: calc(100vh - 150px);
    // overflow-y: auto;
    position: sticky;
    /* flex: 0 0 50%; */
    font-family: "Poppins";
    .btn-primary {
      display: block;
      width: 100%;
      border: none;
      background-color: #2ccae8 !important;
      position: static;
      right: 25.86%;
      font-size: 18px;
      color: #fff;
      padding: 14px 28px;
      font-size: 16px;
      cursor: pointer;
      border-radius: 15px;
      font-weight: 600;
      &:disabled {
        color: #adadad;
        background-color: #d8d8d8 !important;
        cursor: not-allowed;
      }
    }
    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 5px;
    }
    .date {
      border: 1px solid #e1e0e0;
      border-radius: 15px;
      width: 50%;
      display: flex;
      align-items: center;
      height: 48px;
      img {
        margin-left: 20px;
      }
      span {
        margin-left: 20px;
      }
    }
    .time {
      height: 48px;
      width: 50%;
      border: 1px solid #e1e0e0;
      border-radius: 15px;
      display: flex;
      margin-left: 10px;
      align-items: center;
      img {
        height: 42px;
        width: 42px;
        margin-left: 10px;
      }
      p {
        margin-bottom: 0;
        font-size: 14px;
        margin-left: 10px;
      }
      small {
        font-weight: 400;
        font-size: 10px;
        margin-left: 10px;
        color: #858585;
      }
    }
    .select-btn {
      background: #2ccae8;
      border-radius: 15px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      height: 48px;
      margin-top: 20px;
      color: #fff;
      font-family: "Poppins";
      font-weight: 500;
      font-size: 16px;
    }

    @media screen and (max-width: 992px) {
      width: 100%;
      padding: 20px;
      background-color: #fff;
      z-index: 10;
      position: fixed;
      bottom: 0;
      top: auto !important;
      left: 20px;
      right: 20px;
      width: calc(100% - 40px);
      .date {
        font-weight: 400;
        font-size: 12px;
        margin-right: 1px;
      }
      .time {
        font-size: 12px;
        img {
          height: 22px;
          width: 22px;
        }
      }
      .select-btn,
      .chat-btn {
        height: 40px;
        border-radius: 10px;
        font-size: 14px;
        img {
          height: 20px;
          width: 20px;
        }
      }
      .contractor-rating {
        border: 1px solid #e5e5e5;
        border-radius: 50px;
      }
    }
    @media screen and (max-width: 300px) {
      padding: 10px;
      border-radius: 10px;
      .date,
      .time,
      .time p {
        font-size: 10px;
      }
    }
  }

  .select-contractor p {
    text-align: left;
    font-size: 16px;
  }

  .select-contractor-inner::-webkit-scrollbar {
    display: block;
    width: 5px;
  }
  .select-contractor-inner::-webkit-scrollbar-track {
    background: transparent;
  }

  .select-contractor-inner::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-right: none;
    border-left: none;
  }

  .select-contractor-inner::-webkit-scrollbar-track-piece:end {
    background-color: transparent;
    margin-bottom: 20px;
  }

  .select-contractor-inner::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 20px;
  }

  .step-btn {
    cursor: default !important;
    outline: none;
    box-shadow: none;
    &:focus {
      box-shadow: none !important;
    }
  }

  .description {
    padding: 30px 0px 30px 0px;
    font-size: 14px;
    color: #858585;
    border-bottom: 1px solid #e5e5e5;
    span {
      button {
        border: none;
        text-decoration: underline;
        background-color: transparent;
        margin-top: 5px;
        font-size: 14px;
        align-self: flex-start;
        padding-left: 0;
        img {
          width: 7px;
          height: 10px;
          margin-left: 7px;
        }
      }
      .text {
        line-height: 28px;
        max-height: 168px;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .text.full {
        display: block;
        max-height: fit-content;
      }
    }
    @media screen and (max-width: 992px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .services {
    margin-top: 30px;
    padding-bottom: 40px;
    border-bottom: 1px solid #e5e5e5;
    @media screen and (max-width: 992px) {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 30px;
    }
  }
  .service-item {
    display: flex;
    align-items: center;
    margin-top: 35px;
  }

  .service-item img {
    width: 34px;
    height: 34px;
  }

  .service-item .service-name {
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 400;
    margin-left: 35px;
    text-align: left;
    margin-bottom: 0;
    @media screen and (max-width: 992px) {
      font-size: 14px;
    }
    @media screen and (max-width: 300px) {
      margin-left: 10px;
      font-size: 12px;
    }
  }
  .banner {
    margin: 60px 15px 32px 15px;
    background-color: #ecfcff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 81px;
    padding: 0 0 0 39px;
    border-radius: 16px;
  }

  .banner img {
    margin: 0 24px 0 0;
  }

  .highlights {
    color: #030303;
    margin: 32px 16px 32px 16px;
  }

  .headers {
    font-weight: 700;
    font-size: 20px;
    margin: 40px 0 3px 0px;
  }

  .highlights span {
    font-weight: 400;
    font-size: 16px;
  }

  .ratings {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    margin: 70px 0 70px 0;
  }

  .ratings .without-rating {
    font-size: 20px;
    color: #ff6187;
  }

  .ratings .with-rating {
    font-size: 50px;
    color: #ff6187;
  }

  .rating-stars img {
    width: 15px;
    height: 15px;
    margin: 0 2px 0 2px;
  }

  .ratings p {
    font-size: 16px;
    color: #ff6187;
    font-weight: 700;
    margin: 0 8px 0 0;
  }

  .ratings .stars p {
    color: #2cc9e8;
  }

  .empty-star {
    color: #000 !important;
  }

  .portfolio-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 32px 0 32px 0;
    padding: 0 16px;
  }

  .portfolio-buttons button {
    height: 65px;
    max-width: 379px;
    width: 100%;
    border-radius: 15px;
    font-size: 18px;
    margin: 0 0px 32px 0px;
  }

  .portfolio-buttons .primary {
    background-color: #2ccae8;
    border: none;
    color: white;
  }

  .portfolio-buttons .secondary {
    background-color: white;
    border: 1px solid #2ccae8;
    color: #2ccae8;
  }

  .reviews {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e5e5e5;
    @media screen and (max-width: 992px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .reviews-head {
    font-family: "Poppins";
    font-size: 28px;
    display: flex;
    text-align: left;
  }

  .review-container {
    display: flex;
    justify-content: start;
    font-family: "Poppins";
    margin-top: 40px;
    flex-wrap: wrap;
    @media screen and (max-width: 992px) {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
    }
  }

  .review-item {
    display: flex;
    flex-direction: column;
    margin: 0px 9px;
    min-width: 25%;
    max-width: 30%;
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    padding: 20px;
    white-space: break-spaces;
    width: 50%;

    .review-item-top {
      display: flex;
      align-items: center;
      font-size: 14px;
    }
    .review-description {
      font-weight: 400;
      font-size: 12px;
      color: #858585;
      margin-top: 15px;
      text-align: left;
    }
    @media screen and (max-width: 992px) {
      min-width: 80%;
      width: 80%;
      overflow-x: scroll;
      height: fit-content;
      .review-description {
        margin-top: 10px;
      }
    }
  }

  .review-img-container {
    height: 50px;
    width: 50px;
  }

  .review-img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }

  .review-date {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    align-items: flex-start;
    p {
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 5px;
      width: 99%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: left;
    }
  }

  .show-more-btn {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    width: 225px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 10px;
    margin-top: 40px;
  }
  .review-show-more {
    font-weight: 400;
    font-size: 12px;
    border: none;
    background: none;
    width: auto;
    display: flex;
    align-items: center;
    text-decoration: underline;
    img {
      width: 7px;
      height: 10px;
      margin-left: 7px;
    }
  }
  .review-ctn {
    margin: 32px 16px 32px 16px;
  }

  .review-card-ctn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 32px 0 0 0;
  }

  .review-card-ctn .review-card-icon {
    width: 121px;
    height: 121px;
    object-fit: fill;
  }

  .review-card-ctn p {
    margin: 8px 0 0 0;
    font-size: 12px;
    font-weight: 700;
    color: #030303;
  }

  .review-card-rating img {
    width: 15px;
    height: 15px;
    margin: 0 0 0 4px;
    filter: invert(72%) sepia(33%) saturate(1104%) hue-rotate(150deg)
      brightness(92%) contrast(98%);
  }

  .review-card-ctn h2 {
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    text-align: center;
    width: 256px;
    color: #030303;
  }

  .portfolio-chat-service {
    margin: 100px 16px 0;
  }

  @media all and (max-width: 992px) {
    &.carrousels .bear-react-carousel__root {
      height: 350px !important;
    }
    .carrousels .bear-react-carousel__nav-group {
      display: none;
    }
    .star_slider {
      height: 350px !important;
    }
    .star_slider .bear-react-carousel__slide-item {
      border-radius: 0px 0px 0px 0px !important;
    }
  }

  @media all and (max-width: 800px) {
    .portfolio-ctn {
      // width: 600px;
    }
  }

  @media all and (max-width: 600px) {
    .portfolio-ctn {
      width: 100%;
    }

    .insideWrp {
      width: 100%;
    }

    .carrosel-ctn {
      height: 350px;
    }

    .carrousels {
      height: 350px;
      margin-bottom: 50px;
    }

    .available-img {
      width: 30%;
      height: 120px;
    }

    &.carrousels .bear-react-carousel__root {
      height: 350px !important;
    }
    .carrousels .bear-react-carousel__nav-group {
      display: none;
    }
  }

  .contractor-location {
    font-family: "Poppins";
    margin-bottom: 50px;
    @media screen and (max-width: 992px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .address-buttons button {
    width: 124px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #2ccae8;
    border-radius: 5px;
    color: #2ccae8;
    margin-right: 20px;
    margin-bottom: 25px;
    margin-top: 10px;
  }

  .address-buttons button.active {
    background: #2ccae8;
    color: #fff;
    border: none;
  }
}
.confirm-contractor {
  padding: 50px;
  font-family: "Poppins";
  font-style: normal;
  max-width: 1400px;
  margin: 0 auto !important;
  @media screen and (max-width: 992px) {
    padding: 20px;
  }
  .protection {
    display: flex;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 30px;
    padding-bottom: 30px;
    img {
      height: 67px;
      width: 67px;
    }
    div {
      h6 {
        margin-top: 25px;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 20px;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        text-align: left;
        margin-bottom: 20px;
      }
      span {
        margin-bottom: 20px;
      }
    }
  }
  .cost {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 30px;
    padding-bottom: 20px;
    h6 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #2ccae8;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    .cost-label {
      p {
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 20px;
      }
      h4 {
        font-weight: 600;
        font-size: 14px;
        margin-top: 30px;
      }
    }
    .cost-value {
      p {
        text-align: right;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 20px;
      }
      h4 {
        font-weight: 600;
        font-size: 14px;
        text-align: right;
        margin-top: 30px;
      }
    }
  }
  .pay-now-desc {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 12px;
    color: #858585;
    text-align: left;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 20px;
  }
  .select-address {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    border-bottom: 1px solid #e5e5e5;
    h4 {
      font-weight: 600;
      font-size: 14px;
    }
    .add-address {
      border: none;
      outline: none;
      background-color: transparent;
      color: #2ccae8;
      font-weight: 500;
      font-size: 14px;
      display: flex;
      padding: 0;
      margin-top: 15px;
      width: fit-content;
      &:hover {
        text-decoration: underline;
      }
    }
    .address {
      border: 1px solid #e5e5e5;
      border-radius: 15px;
      padding: 25px 30px;
      margin: 9px 0px;
      display: flex;
      align-items: start;
      cursor: pointer;
      &.selected {
        border: 1px solid #2ccae8;
      }
      p {
        font-weight: 400;
        font-size: 14px;
      }
      img {
        margin-right: 25px;
      }
    }
  }
  .pricing-info {
    margin-top: 25px;
    display: flex;
    align-items: start;
    img {
      margin-right: 25px;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      padding: 0;
      margin: 0;
      text-align: left;
    }
  }
  .buttons-container {
    display: block;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 20px;
    button {
      width: 100%;
      margin: 10px 0px;
      border-radius: 15px;
      font-weight: 400;
      font-size: 14px;
      height: 50px;
      border: none;
    }
    .confirm {
      background: #2ccae8;
      color: #ffffff;
      &:disabled {
        background-color: #e5e5e5;
        color: #6b6b6b;
        cursor: not-allowed;
      }
    }
    .grey {
      background: #e5e5e5;
    }
    .cancel {
      background-color: transparent;
    }
  }
  .help {
    width: 100%;
    margin: 10px 0px;
    border-radius: 15px;
    font-weight: 400;
    font-size: 14px;
    height: 50px;
    border: none;
    background: #e5e5e5;
  }
  .details-card-mobile {
    border-bottom: 1px solid #e5e5e5;

    @media screen and (max-width: 992px) {
      .verified-btn {
        background: #2ccae8;
        border: 1px solid #2ccae8;
        border-radius: 50px;
        font-weight: 400;
        font-size: 10px;
        height: 24px;
        padding-bottom: 0;
        margin-top: 0;
        align-items: center;
        width: 107px;
      }
      .verified {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #2ccae8;
        margin-bottom: 0;
        margin-left: 20px;
      }
      .contractor-image-container {
        width: 76px;
        height: 76px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .name {
        font-size: 16px;
        margin-bottom: 10px;
        text-align: left;
      }
      .exp {
        font-size: 12px;
        text-align: left;
        span {
          color: #2cc9e8;
        }
      }
      .rating {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin: 0;
        border: 1px solid #e5e5e5;
        border-radius: 50px;
        height: 24px;
        justify-content: center;
        padding: 0px 5px;
      }
    }
  }

  .details-card-primary {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }

  .details-card-secondary {
    border-bottom: 1px solid #e5e5e5;
    padding: 35px 30px 35px 0px;
    span {
      color: #2ccae8;
    }
    p {
      span {
        color: #2ccae8;
        text-decoration: none;
        cursor: pointer;
      }
      span:hover {
        text-decoration: underline;
      }
    }
  }

  .details-card-secondary p {
    font-size: 14px;
    color: #858585;
    text-align: left;
  }

  .details-card-left {
    width: 60%;
    .contractor-rating {
      font-size: 12px;
      font-weight: 400;
      color: #222222;
    }
    .contractor-info {
      max-width: 70%;
    }
    .contractor-name {
      font-size: 22px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
    .verified {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #2ccae8;
      margin-bottom: 0;
    }
  }

  .contractor-image-container {
    width: 96px;
    height: 96px;
    position: relative;
    .contractor-img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
    .checked-image {
      position: absolute;
      bottom: -10px;
      right: -5px;
    }
  }
  .details-card-right {
    width: 40%;
    margin-top: 5px;
    p {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .view-more {
    position: absolute;
    bottom: 20px;
    right: 30px;
    // width: 153px;
    height: 40px;
    padding: 10px 20px;
    background: rgba(32, 32, 32, 0.65);
    border-radius: 10px;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    cursor: pointer;
    @media screen and (max-width: 900px) {
      width: 115px;
    }
  }
}

.owner-profile {
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
  margin: 0 auto !important;
  .profile-head {
    height: 70px;
    align-items: center;
    display: flex;
    padding-left: 50px;
    img {
      height: 40px;
      width: 40px;
    }
  }
  .profile-top {
    background: #2ccae8;
    height: 200px;
    .profile-img {
      object-fit: cover;
      width: 180px;
      height: 180px;
      border: 2px solid #ffffff;
      border-radius: 50%;
      position: absolute;
      top: 70px;
    }
    .close-icon {
      height: 32px;
      width: 32px;
      margin-top: 10px;
    }
    .edit-icon {
      bottom: -50px;
      left: 120px;
      cursor: pointer;
    }
    @media screen and (max-width: 992px) {
      height: 100px;
      .profile-img {
        width: 130px;
        height: 130px;
        top: 50px;
      }
      .edit-icon {
        left: 90px;
        height: 46px;
        width: 46px;
        bottom: -80px;
      }
    }
  }
  .profile-bottom {
    padding-top: 90px;
    h4 {
      font-weight: 600;
      font-size: 22px;
      color: #000000;
      margin-bottom: 5px;
    }
    .profile-headings {
      height: 60px;
      align-items: center;
      border-bottom: 1px solid #e5e5e5;
      border-top: 1px solid #e5e5e5;
      margin-bottom: 30px;
      @media screen and (max-width: 992px) {
        border-top: none;
      }
    }
    .profile-links {
      a,
      span {
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: start;
        color: #000000;
        font-family: "Poppins";
        font-weight: 400;
        font-size: 12px;
        margin: 15px 0px;
      }
    }
    .edit-profile {
      border: none;
      outline: none;
      background-color: transparent;
      color: #2ccae8;
      font-weight: 500;
      font-size: 14px;
      display: flex;
      padding: 0;
      margin-top: 5px;
      margin-bottom: 20px;
      width: fit-content;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.gallery-container {
  overflow: clip;
  position: relative;
  height: 100%;
  max-height: 100%;
  width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  max-width: 1400px;
  margin: 0 auto !important;
}
.gallery-header {
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  max-width: 1400px;
  margin: 0 auto !important;
  img {
    cursor: pointer;
    &:hover {
      filter: grayscale(100%);
    }
  }
}
.image-gallery {
  display: grid;
  width: 100%;
  gap: 8px !important;
  place-items: center;
  margin-top: 60px;
  flex: 1 1 auto !important;
  overflow-y: auto !important;
  outline: none !important;
  .sub-container {
    display: grid;
    gap: 8px !important;
    width: 60%;
    margin: 50px 0px;
  }
  @media screen and (max-width: 992px) {
    padding: 0px 20px;
    .sub-container {
      width: 100%;
    }
  }
}

.image-grid__row-half {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px !important;
}

.image-grid__row {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 8px !important;
}

.image-grid__item--half {
  display: grid !important;
  grid-column: span 1;
  gap: 8px !important;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.image-grid__item--full {
  display: grid !important;
  grid-column: span 1;
  gap: 8px !important;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.time-picker {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  label {
    // border-bottom: 1px solid #858585;
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
    &:hover {
      background-color: #f5f5f5;
    }
    &.selected {
      // background-color: #f5f5f5;
    }
    img {
      margin: 0px 10px;
    }
  }
}

.mobile-drawer {
  position: relative;
  padding-bottom: 100px !important;
  .btn-primary {
    display: block;
    width: 100%;
    border: none;
    background-color: #2ccae8 !important;
    position: static;
    right: 25.86%;
    font-size: 18px;
    color: #fff;
    padding: 14px 28px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 15px;
    font-weight: 600;
    margin-top: 50px;
    &:disabled {
      color: #adadad;
      background-color: #d8d8d8 !important;
      cursor: not-allowed;
    }
  }
  .insideWrp {
    margin-bottom: 100px;
  }
}

input[type="checkbox"].confirm-check {
  // background-image: url("../../_assets/images/home-images/square.png");
  // background-repeat: no-repeat;
  // background-size: 100%;
  // background-color: transparent;
  // border: none;
  height: 24px;
  width: 24px;
  &::after {
    height: 24px;
    width: 24px;
  }
  margin-right: 28px;
  border-width: 2px;
  margin-top: 5px;
  @media screen and (max-width: 992px) {
    height: 20px;
    width: 20px;
    &::after {
      height: 20px;
      width: 20px;
    }
  }
}

input[type="checkbox"].confirm-check:checked {
  background-image: url("../../_assets/images/home-images/checkBlue.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: transparent;
  background-position: 2px -2px;
  margin-right: 28px;
}
.MuiBackdrop-root {
  max-width: 1400px !important;
  margin: 0 auto !important;
}
.inspirations,
.chat-btn-contnr {
  @media screen and (max-width: 992px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
