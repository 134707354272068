.notificationsWrapper {
    margin: 34px 0px 0px;
}

.notificationsTitle {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    margin: 0px 18px 15px;
}

.notificationsTitleWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    border-bottom: 1px solid #e4e4e4;
    box-shadow: 0px 1px 2px -1px #e4e4e4;
}

.notificationsTitleWrapper svg {
    margin-right: 34px;
}

.notificationsBlock {
    display: flex;
    justify-content: space-between;
    /* height: 130px; */
    height: 85px;
    padding: 0px 18px;
}

.notificationsBlock:not(:last-child) {
    border-bottom: 0.5px solid #f1f1f1;
}

.notificationsDotWrapper {
    margin: auto 20px;
}

.notificationsDot {
    width: 9px;
    height: 9px;
    background: #2CCAE8;
    border-radius: 50%;
}

.notificationsMessage {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    width: 100%;
    margin: auto;
}

.notificationsDate {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #6D6D6D;
    margin-top: 8px;
}

.notificationsImage {
    margin: auto;
    min-width: 45px;
    height: 45px;
    background: #2CCAE8;
    border-radius: 50%;
    display: flex;
}

.notificationsImage img {
    margin: auto;
}

.line {
    width: 80%;
    background: #f1f1f1;
    height: 5px;
    border-radius: 100% 0;
    border: none !important;
}