.flex {
  display: flex;
}

.grid {
  display: grid;
}

.home-banner {
  height: 77vh;
  background-size: cover;
  background-position: top;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.home-title {
  font-weight: bold;
  font-size: 25px;
  line-height: 28px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  /* margin-top: 112px; */
}

.homepage-title {
  font-weight: bold;
  font-size: 28px;
  line-height: 35px;
  color: #fff;
  font-family: Poppins;
  padding-bottom: 30px;
}

.homePadding20 {
  padding: 20px;
}

.home-search {
  padding: 5px;
  width: 99%;
  background-color: #fff;
  height: 50px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.home-search-input {
  height: 40px;
  border: none;
  border-radius: 100px;
  width: 94%;
}

.home-search-input:focus {
  border: none;
  outline: none;
}

.home-search-icon {
  border-radius: 100%;
  background-color: #2ccae8;
  color: #fff;
  height: 28px;
  width: 30px;
  padding: 10px;
}

.home-section {
  padding: 20px 20px;
}

.homePaddingtop15 {
  padding-top: 15px;
}

.homePaddingtop20 {
  padding-top: 20px;
}

.homePaddingtop30 {
  padding-top: 30px;
}

.homePaddingtop40 {
  padding-top: 40px;
}

.home-section1 {
  padding-left: 20px;
  padding-right: 20px;
}

.home-sectionpadd20 {
  padding: 20px;
}

.dotPosition {
  position: relative;
}

.homeDrawertxt {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 47px;
  text-align: left;
  color: #030303;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.homeDrawertxt:hover {
  color: #2ccae8;
}

.home-section-categories {
  margin-top: 15px;

  /* flex-wrap: wrap;
  justify-content: center; */
  font-family: Poppins;
}

.home-section-categories img {
  /* height: 135px;
  border-radius: 20px;
  width: 135px;
  object-fit: cover;
  margin: 5px 12px; */

  height: 120px;
  width: 100%;
  -webkit-box-shadow: 0px 0px 10px 0px #4d4d4d;
  -moz-box-shadow: 0px 0px 10px 0px #4d4d4d;
  box-shadow: 0px 0px 10px 0px #4d4d4d;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -khtml-border-radius: 8px;
  border-radius: 8px;
  border: solid white 3px;
  float: left;
  margin-right: 25px;
}

.home-section-categories1 {
  margin-top: 15px;
  text-align: center;
  display: flex;
  /* flex-wrap: wrap;
  justify-content: center; */
  font-family: Poppins;
}

.home-section-categories1 img {
  /* height: 135px;
  border-radius: 20px;
  width: 135px;
  object-fit: cover;
  margin: 5px 12px; */

  border-radius: 16px;

  /* Inside Auto Layout */
  width: 100%;
  height: 115px;
  margin-right: 10px;
}

.home-section-projects {
  position: relative;

  clear: both;
  margin: 0;
  padding-top: 15px;
  white-space: nowrap;
  overflow-x: auto;
}

.home-section-projects::-webkit-scrollbar {
  display: none;
}

.home-section-project {
  display: inline-block;
  width: 334px;
  height: 396px;
  margin: 20px;
  border-radius: 20px;
  bottom: 0;
  text-align: center;
  color: #fff;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 66.83%, #000000 100%);
  vertical-align: bottom;
  align-items: center;
}

.home-section-project div {
  margin-left: 140px;
  height: 53px;
  position: absolute;
  bottom: 0;
}

.home-section-dream {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
  justify-content: center;
  align-items: center;
}

.home-section-dream img {
  width: 7%;
  margin-bottom: -5px;
  margin-left: 7px;
}

.home-section-dream button {
  border: 1px solid #2ccae8;
  box-sizing: border-box;
  border-radius: 15px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #2ccae8;
  height: 50px;
  width: 40%;
  margin: 20px 5px;
  background-color: #fff;
  font-family: Poppins;
}

.home-section-dream button:hover {
  box-sizing: border-box;
  border-radius: 15px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #fff;
  height: 50px;
  width: 100%;
  margin: 20px 5px;
  background-color: #2ccae8;
  font-family: Poppins;
}

.home-testimonial {
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

header {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  position: fixed;
  color: #fff;
  width: 100%;
  top: 0;
  z-index: 100;
}

.header-white {
  background-color: none;
}

.header-blue {
  background-color: #2ccae8;
}

.home-testimonial img {
  width: 150px;
  height: 150px;
  border-radius: 100%;
}

.bold {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  color: #000000;
}

.italic {
  font-style: italic;
}

.center {
  text-align: center;
}

.show_more {
  margin-top: 32px;
  margin-bottom: 32px;
  margin-left: 48px;
  font-size: 16px;
  color: #2ccae8;
  line-height: 28px;
  font-weight: bold;
  font-family: Poppins;
}

.show_more_service {
  margin-top: 32px;
  font-size: 16px;
  color: #2ccae8;
  line-height: 28px;
  font-weight: bold;
  font-family: Poppins;
}

.bet_style {
  display: flex;
  border-radius: 8px;
  font-style: italic;
  font-family: Poppins;
  background-color: #dfdfdf;
  height: 313px;
  font-size: 17px;
  font-style: italic;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  /* padding: 5px; */
  color: #858585;
}

.contract_div {
  /* margin-top: 30px; */
  width: 100%;
}

.contract {
  position: absolute;
  margin-top: 30px;
  margin-left: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
  color: #030303;
}

.contract1 {
  position: absolute;
  margin-top: 50px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
  color: #030303;
  margin-left: 20px;
}

.work {
  position: absolute;
  margin-top: 80px;
  font-size: 16px;
  margin-left: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #030303;
}

.span_style {
  font-size: 16px;
  color: black;
  font-family: Poppins;
  font-weight: bold;
}

.form_text_left {
  background: #fffefe;
  /* border-radius: 10px; */
  width: 32%;
  padding: 52px 0px 15px 0px;
  border-bottom-color: none;
  margin-right: 60px;
  font-style: italic;
  font-size: 15px;
  padding: 10px;
  color: #adadad;
  font-family: Poppins;
}

.form_text {
  background: #fffefe;
  border-radius: 16px;
  width: 30%;
  padding: 52px 0px 15px 0px;
  /* box-shadow: 11px 11px 11px rgba(0, 0, 0, 0.07); */
  border: 0.5px solid #d8d8d8;
  /* margin-right: 60px; */
  /* margin-top: 20px; */
  font-style: italic;
  font-size: 18px;
  padding: 16px;
  color: #adadad;
  height: 30vh;
  margin-top: 10px;
  font-family: Poppins;
}

.text_area {
  display: flex;
  align-items: center;
  height: 10px;
}

.form_btn {
  display: flex;
  align-items: center;
  border: none;
  background: #2ccae8;
  border-radius: 20px;
  text-align: center;
  padding: 20px 0px;
  font-family: Poppins;
  font-weight: bold;
  justify-content: center;
  width: 33%;
  margin-top: 130px;
  color: #fffefe;
  margin-bottom: 20px;
}

.footer_section {
  display: flex;
  width: 100%;
}

.left_div {
  background-color: #f5f5f5;
  width: 73%;
  height: 60vh;
  margin-top: 40px;
}

.right_div {
  display: flex;
  flex-direction: column;
  background-color: #e9fbff;
  padding: 10px;
  margin-top: 40px;
  width: 350px;
  padding: 29px 0 0 0;
}

.headerMain {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 25px;
}

.headerTitle {
  font-weight: bold;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #2ccae8;
}

.headerRight {
  display: flex;
  justify-content: center;
  gap: 15px;
  justify-content: space-evenly;
}

.signup_text {
  /* margin-bottom: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px; */
  /* identical to box height */

  /* text-align: center;

  color: #FFFFFF; */
  margin-bottom: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #ffffff;
  max-width: 100px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 0 !important;
  padding-left: 5px;
  padding-right: 5px;
}

.header_menus {
  /* display: flex; */
  display: none;
  padding: 20px;
  text-align: left;
  margin-top: -17px;
  justify-content: space-between;

  font-weight: bold;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  color: #fefefe;
}

.heading {
  color: #2ccae8;
  font-size: 35px;
  font-family: Poppins;
  justify-content: center;
}

.header-menu {
  display: flex;
  /* justify-content: space-between; */
  justify-content: flex-end;
  box-sizing: border-box;
  align-items: center;
}

.mobile_menu {
  /* display: none; */
  display: block;
  position: relative;
  /* margin-left: 15px; */
}

a:link {
  text-decoration: none;
}

.hidden {
  display: none;
}

.tyh {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 27px;
  /* identical to box height */

  color: #000000;
}

.tyh1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 27px;
  margin-left: 5px;

  color: #000000;
}

textarea {
  width: 100%;

  padding: 12px 20px;
  font-size: 16px;
  resize: none;
  outline: none;
}

textarea::placeholder {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b5b5b5;
}

/* @media screen and (min-width: 768px) and (max-width: 768px) {
  .headerMain {
    position: absolute !important;
    left: 0 !important;
    top: 0;
  }
} */

@media all and (min-width: 768px) {
  .headerMain {
    position: absolute !important;
    left: 0 !important;
    top: 0;
  }

  #quartID {
    margin-top: 80px !important;
  }

}

@media all and (min-width: 769px) {
  .headerMain {
    position: relative !important;
    left: 0 !important;
    top: 0;
  }
}

@media all and (max-width: 768px) {
  .home_Wrapper .headerMain {
    position: absolute;
    left: 0;
    top: 0;
  }

  #quartID {
    margin-top: 35px !important;
  }
}

@media all and (max-width: 768px) {
  .header_menusHide {
    display: none;
  }

  .mobile_menu {
    /* display: block;
    width: 29.25px;
    height: 19.5px;
    top: 9.75px;
    left: 4.88px;
    left: 12.5%;
    right: 12.5%;
    top: 25%;
    bottom: 25%;
    margin-left: 22.87px; */
    display: block;
    top: 0px;
    padding-right: 5px;
  }

  .mobile_menu.overHamburger {
    position: relative;
  }

  .home-search {
    padding: 5px;
    width: 100%;
    background-color: #fff;
    height: 50px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .paddingsearch20 {
    padding-right: 10px;
  }
}

@media all and (max-width: 480px) {
  .header_menusHide {
    display: none;
  }

  .mobile_menu {
    /* display: block;
    width: 29.25px;
    height: 19.5px;
    top: 9.75px;
    left: 4.88px;
    left: 12.5%;
    right: 12.5%;
    top: 25%;
    bottom: 25%;
    margin-left: 22.87px; */
  }

  .home-search {
    padding: 5px;
    width: 96%;
    background-color: #fff;
    height: 50px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .paddingsearch20 {
    padding-right: 10px;
  }
}

@media all and (max-width: 320px) {
  .header_menusHide {
    display: none;
  }

  /* .mobile_menu {
    display: block;
    width: 29.25px;
    height: 19.5px;
    top: 9.75px;
    left: 4.88px;
    left: 12.5%;
    right: 12.5%;
    top: 25%;
    bottom: 25%;
    margin-left: 22.87px;
  } */

  .home-search {
    padding: 5px;
    width: 96%;
    background-color: #fff;
    height: 50px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .paddingsearch20 {
    padding-right: 10px;
  }
}

@media all and (max-width: 240px) {
  .header_menusHide {
    display: none;
  }

  .mobile_menu {
    display: block;
    /* width: 29.25px;
    height: 19.5px;
    top: 9.75px;
    left: 4.88px;
    left: 12.5%;
    right: 12.5%;
    top: 25%;
    bottom: 25%;
    margin-left: 22.87px; */
  }

  .header-menu {
    flex-wrap: wrap;
  }

  .home-search {
    padding: 5px;
    width: 96%;
    background-color: #fff;
    height: 50px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .paddingsearch20 {
    padding-right: 10px;
  }
}

@media only screen and (min-width: 769px) {
  #root {
    padding: 20px 25px;
    padding-bottom: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .home-banner {
    padding: 60px 30px;
    box-sizing: border-box;
    height: auto;
    border-radius: 25px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 1200px !important;
    margin: 0 auto !important;
    background: url("../../_assets/images/home-images/pc_banner.png") !important;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }

  .home-banner .homepage-title span {
    display: block;
  }

  .home-banner .homepage-title span:nth-child(1) {
    font-size: 28px !important;
  }

  .home-banner .homepage-title span:nth-child(2) {
    font-size: 18px !important;
  }

  .home-banner table {
    max-width: 435px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 25px;
    padding: 45px 35px !important;
    height: 100%;
    padding-bottom: 20px !important
  }

  .headerMain {
    position: relative;
    margin-top: 0;
    left: 0;
    padding-bottom: 25px;
    background-color: #ffffff;
    max-width: 1200px !important;
    margin: 0 auto !important;
  }

  .headerMain table {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .headerTitle,
  .signup_text {
    color: #2ccae8;
  }

  .homepage-title {
    color: #000000;
  }

  .slide-pc {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
  }

  .home-section-projects {
    width: 280px;
  }

  .home-section .grid.home-grid {
    clear: both;
    max-width: 750px;
    align-items: center;
    margin: 0 auto;
    padding: 50px 0;
    position: relative;
  }

  .home-section .grid.home-grid .contract {
    margin-top: -30px !important;
  }

  .home-section .grid.home-grid .contract1 {
    margin-top: 0 !important;
  }

  .home-section .grid.home-grid .work {
    margin-top: 30px !important;
  }

  .home-section .grid.home-grid .contract,
  .home-section .grid.home-grid .contract1,
  .home-section .grid.home-grid .work {
    /* margin-left: 65px; */
    left: 55px;
    top: 50%;
    margin: 0;
  }

  .slide-pc .flex.catall.homePaddingtop15,
  .slide-pc .flex.homePaddingtop15 {
    padding-top: 0;
  }

  .slide-pc .slider-container.Home_Proj_slide+.flex.catall.homePaddingtop15,
  .home-section-projects .slider-container.Home_Proj_slide+.flex.homePaddingtop15,
  .home-section-projects .slider-container.Home_Proj_slide+div .flex.homePaddingtop15 {
    padding-top: 5px;
  }

  .home-section1.home-form {
    max-width: 625px !important;
  }

  .home-form-sec {
    max-width: 520px;
    margin: 0 auto;
    padding-top: 50px;
  }

  #quartID {
    padding-bottom: 80px;
  }

  #quartID .show_more_service {
    display: none;
  }

  .slide-pc .home-section-projects .slider-container,
  .slide-pc .home-section-projects .slider-container .slides {
    height: 175px;
  }

  .slide-pc .home-section-projects .slider-container .slides .slide-image {
    height: 100%;
    object-position: center;
    object-fit: cover;
  }

  .slide-pc .home-section-projects .slider-container .prev,
  .slide-pc .home-section-projects .slider-container .next {
    margin-top: -2rem;
    font-size: 18px;
  }

  .home_section_wrp .slide-pc .home-section-projects .all-dots {
    bottom: 5px;
  }

  .home_section_wrp .slide-pc .home-section-projects .all-dots .dot {
    height: 7px;
    width: 7px;
  }
}

@media all and (max-width: 768px) {
  .displayNone {
    display: none !important;
  }
}

@media only screen and (max-width: 390px) {
  .insideWrp img.innerLogo {
    width: 23px !important;
    height: 23px !important;
    top: -4px !important;
  }

  .headerMain .headerTitle {
    padding-left: 25px !important;
    font-size: 14px !important;
  }

  .home_Wrapper img.homeLogo {
    width: 23px !important;
    height: 23px !important;
    top: -4px !important;
  }
}

@media only screen and (min-width: 390px) {

  /* .insideWrp img.innerLogo {
    width: 23px !important;
    height: 23px !important;
    top: -4px !important;
  } */
  .home_Wrapper img.homeLogo {
    width: 35px !important;
    height: 35px !important;
    top: -10px !important;
  }

  .headerMain .headerTitle {
    padding-left: 40px !important;
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 385px) {


  .headerMain .headerTitle {
    padding-left: 25px !important;
    font-size: 14px !important;
  }

  .header-menu .pc-img {
    width: 23px;
  }

  .signup_text {
    max-width: 60px;
  }
}

@media only screen and (max-width: 350px) {
  .home_Wrapper img.homeLogo {
    width: 23px !important;
    height: 23px !important;
  }

  .home_Wrapper .headerMain .headerTitle {
    padding-left: 25px !important;
    font-size: 14px !important;
  }

  .header-menu .app-img {
    width: 17px;
  }

  .signup_text {
    max-width: 60px;
  }

  .homeService_Wrp .allCenter.bold {
    justify-content: left;
    white-space: nowrap;
  }

  .bet_style label {
    text-align: center;
  }

  .bet_style {
    height: auto;
    padding: 15px;
  }

  .banner-icon.flex {
    flex-direction: column;
  }

  .banner-icon.flex div {
    margin-bottom: 6px;
    cursor: pointer;
  }

  .contract {
    margin-top: 7px;
    font-size: 12px;
  }

  .contract1 {
    margin-top: 27px;
    font-size: 12px;
  }

  .work {
    margin-top: 50px;
    font-size: 12px;
  }

  .work img {
    width: 10px;
  }
}