@import url("https://fonts.googleapis.com/css?family=Roboto:400,100,300,100italic,300italic,400italic,500italic,500,700,700italic,900,900italic");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.signup_editBtn {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  margin-top: 15px;
  color: #2ccae8;
}

.signup_page_anyhnb_logo {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 52px;
  color: #2ccae8;
  padding-top: 35px;
}

.signup_header {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 37px;
  color: #000000;
  margin-top: 64px;
}

.signup_sub_heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding-top: 30px;
}

.signup_flex_with_center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.arrow_icon {
  margin-top: 7px;
  margin-left: -57px;
}

.arrow_icon_left {
  margin-left: -80px;
  margin-top: 61px;
  position: absolute;
}

table {
  width: 100%;
}

.signup_center_align {
  text-align: center;
  margin: 20px;
}

.signup_sign_linkbtn {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #5b75ff;
  padding-left: 4px;
  padding-top: 30px;
}

.signup_home_section {
  font-family: Poppins;
  border: none;
  border-radius: 10px;

  grid-gap: 11px;
  border: 1px solid #d8d8d8;
  text-align: left;
  margin-top: 8px;
  padding: 21px 22px;
  font-style: normal;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
}

.signup_eyeIcon {
  margin-left: -30px;
  cursor: pointer;
  color: #000;
  height: 18px;
  width: 21.63800048828125px;
  padding-top: 45px;
}

.signup_section {
  padding: 17px;
  padding-top: 64px;
}

.signup_property_section {
  padding-top: 60px;
}

.signup_first_div_header {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.signup_second_div_header {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  color: #adadad;
}

.signup_property_headings {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 37px;
  color: #000000;
}

.signup_property_div_Option {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #030303;
}

.register_page_lable {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  margin-top: 16px;
  color: #000000;
}

::placeholder {
  font-family: Poppins;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #adadad;
  padding-left: 10px;
}

.registration_txtbox_section {
  padding: 22px;
  padding-top: 48px;
}

.terms_and_codition {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  /* color: #ADADAD; */
}

.terms_and_codition_gap {
  padding-left: 5px;
  padding-right: 5px;
  color: #adadad;
}

.terms_and_codition_color {
  color: #adadad;
}

.signup_continue_button {
  font-family: Poppins;
  align-items: center;
  border: none;
  background: #2ccae8;
  text-align: center;
  /* padding: 20px 147px; */
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  width: 100%;
  color: #fffefe;
  font-weight: 600;
  justify-content: space-evenly;
  display: flex;

  height: 65px;
  border-radius: 15px;
}

.input-text {
  width: 100%;
}

.margin_line {
  margin-top: 36px;
  display: flex;
  padding-left: 0px;
  padding-right: 0px;
}

.signup_or {
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
}

.signup_line {
  padding-left: 23px;
  padding-right: 23px;
  margin: -4px;
}

.signup_button_grp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3vh;
  width: 100%;
}

.signup_button_padding {
  margin-top: 48px;
  margin-left: 16px;
  margin-bottom: 36px;
  margin-right: 16px;
}

.signup_sub_button {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #030303;
  cursor: pointer;

  background: #fefefe;

  border: 0.5px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px;
}

.signup_media_button {
  padding-left: 50px;
}

.signup_media_button_inside {
  padding-left: 30px;
}

.signup_media_button_outside {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 8px;
}

.signup_media_button_icon {
  width: 35px;
  height: 35px;
  object-fit: contain;
  object-position: center;
}

.signup_needHelp {
  align-items: center;
  text-align: center;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #5b75ff;
  padding-top: 60px;
}

.signup_termsColor {
  color: #5b75ff;
  width: max-content;
}

.signup_line_width {
  width: 100%;
}

.signup_input_border {
  border-bottom: 0.5px solid #d8d8d8;
  border: none;
}

.signup_validation_header_first {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  padding-top: 10px;
  color: #000000;
}

input {
  width: 100%;
  border: 0;
  border-bottom: 0.5px solid #d8d8d8;
  border-color: #d8d8d8;
  font-size: 16px;
  font-style: italic;
  font-family: Poppins;
  line-height: 100%;
  height: 50px;
}

.signup_validation_header_second {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #000000;
}

.signup_validation_box {
  width: 67px;
  height: 67px;
  top: 0px;

  border: 0.5px solid #030303;
  border-radius: 12px;

  margin: 0px 8px;
}

.signup_validation_input {
  width: 62px;
  align-items: center;
  text-align: center;
  border: none;
  margin-top: 7px;
  padding: 0px !important;
}

.signup_validationbox_padding {
  padding-top: 35px;
}

.signup_validationbox_inside_padding {
  padding-top: 1px;
  padding-left: 1px;
  padding-right: 5px;
  padding-bottom: 5px;
}

.signup_validation_not_receive_link {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  background: none;
  text-align: center;
  border: none;

  color: #5b75ff;
}

.timer {
  font-size: 12px;
  color: red;
}

.signup_validation_not_receive_link:disabled {
  opacity: 0.3;
  pointer-events: none;
  cursor: not-allowed;
}

.signup_validation_not_receive_link_padding {
  margin-top: 30px;
}

.signup_validation_con_button_padding {
  margin-top: 30px;
  margin-left: 16px;
  margin-bottom: 36px;
  margin-right: 16px;
}

.signup_validation_icon {
  display: flex;
  justify-content: right;
  align-items: right;
  width: 90%;
  margin-top: -50px;
  padding: 17px;
  /* margin-left: 90%;
  margin-top: -69px;
  margin-right: 80px;
  padding: 10px; */
}

.signup_validation_password_icon {
  display: flex;
  justify-content: right;
  align-items: right;
  width: 85%;
  margin-top: -72px;
  padding: 17px;
  /* margin-left: 90%;
  margin-top: -69px;
  margin-right: 80px;
  padding: 10px; */
}

.signup_tick_icon {
  display: flex;
  justify-content: right;
  align-items: right;
  width: 85%;
  margin-top: -56px;
  padding: 17px;
}

.signup_validation_error_img {
  width: 20px;
  height: 20px;
  margin-top: 25px;
}

.signup_validation_success_img {
  width: 17px;
  height: 12px;
  margin-top: 25px;
}

.ssignin_rec_padding {
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
}

.ssignin_rec_padding_top {
  padding-top: 100px;
}

.signin_checkmail_txt {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #000000;
}

.signin_checkmail_bold {
  font-family: Poppins;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #000000;
}

.signincheckmail_table {
  width: 100%;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.generate_pass_btn_padd {
  padding-top: 70px;
}

.passwordsuccess_table {
  width: 100%;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.passwordsuccess_btn_padd {
  padding-top: 30px;
}

.signup_validation_input:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}
