


#toggle {
  display: none;
}

.nav {
  position: fixed;
  width: 100%;
  height:calc(100%-50px);
  background-color:white;
  top: -100%; left: 0; right: 0; bottom: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  /* to stop flickering of text in safari */
  /* transition: all 0.8s; */
  transform: scale(0);
  z-index: 999999;
}


#toggle:checked + .hamburger + .nav {
  top: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  transform: scale(1);
}

