.kitchen-piggy-image {
  width: 70px;
  height: 60px;
}

.kitchen-piggy-image img {
  width: 70px;
  object-fit: cover;
}

.kitchen_radio_div_first {
  border-radius: 8px;
  color: #070707;
  border: 0.5px solid #d8d8d8;

  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.kitchendiv_radiobtn_paddingright {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 30px;
  padding-right: 30px;
}

.width100 {
  width: 100%;
}

.kitgrid {
  display: grid;
}

.kitpadd20 {
  padding-left: 20px;
}

.kitchen-slider-container {
  height: 282px;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.kitchenslide-image {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 8px;
}

.checkoutslide-image {
  width: 100%;
  height: 400px;
  border-radius: 8px;
  background-repeat: no-repeat;
}

.tranformplus {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.tranformmius {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.checkout-slider-container {
  height: 250px;
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
}

.checkoutTable {
  width: 100%;
}

.checkoutPaytxtbold {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #030303;
}

.checkoutPaytxtnormal {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #030303;
}

.checkoutPaytxtcolor {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #11d20d;
}

.checkPaygray {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #adadad;
}

.check_radio_div_blue {
  border-radius: 8px;
  color: #070707;
  border-top: 0.5px solid #d8d8d8;
  border-bottom: 0.5px solid #d8d8d8;
  border-right: 0.5px solid #d8d8d8;
  border-left: 9px solid #5b75ff;

  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.check_radio_div_yellow {
  border-radius: 8px;
  color: #070707;
  border-top: 0.5px solid #d8d8d8;
  border-bottom: 0.5px solid #d8d8d8;
  border-right: 0.5px solid #d8d8d8;
  border-left: 9px solid #eabb74;

  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkoutCalsvg {
  border-radius: 8px;
  color: #070707;

  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkLblbtn {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;

  color: #2ccae8;
}

.paddingall10 {
  padding: 10px;
}

.paddingTop140 {
  padding-top: 140px;
}

.paddingTop100 {
  padding-top: 100px;
}

.checkoutRatings {
  width: 43px;
  height: 37px;
  background: #ff6187;
  border-radius: 8px 0px 4px 0px;
}

.checkoutRatingstxt {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  color: #fefefe;
}

.checkoutRatingstxtdetails {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  color: #030303;
}

.checkoutRatingstxtdetailssub {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: #030303;
}

.checkoutRatingsdiv {
  display: block;
  font-size: 8pt;
  text-align: left !important;
  text-decoration: none;
}

.checkOutratingbottom {
  padding-top: 50px;
}

.checkoutslide-image-gradient {
  position: relative;

  height: 225px;
  background: linear-gradient(180deg, rgba(9, 9, 9, 0) 65.9%, #090909 99.97%);
}

.checkoutParagraphtxt {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: justify;
  color: #000000;
}

.checkoutOrdertxt {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 28px;
  text-align: center;
  color: #fefefe;
}

.checkoutOrdersubtxt {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 28px;
  color: #fefefe;
}

.checkOut-bottomtxt {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #fefefe;
}

.checkOut-seeprotolotxt {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #2ccae8;
}

.paddinngtop20 {
  padding-top: 20px;
}

.paddinngtop30 {
  padding-top: 30px;
}

.paddinngtop40 {
  padding-top: 40px;
}

.paddinngtop15 {
  padding-top: 15px;
}

.paddinngtop10 {
  padding-top: 10px;
}

.kitchactive {
  display: inline-block;
}

.kitchinactive {
  display: none;
}

.kitchhome-section-projects {
  position: relative;

  clear: both;
  margin: 0;
  /* padding-top: 15px; */
  white-space: nowrap;
  overflow-x: auto;
}

.imgtxt {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #030303;
}

.padding2050 {
  padding-top: 17px;
  padding-bottom: 45px;
}

.grid-slider.padng-top6 .padding2050,
.grid-slider.padng-top6 .padding1720 {
  padding-top: 5px;
}

.padding1720 {
  padding-top: 17px;
  padding-bottom: 20px;
}

.padding10 {
  padding-top: 10px;
}

.kitch_radio_div_firstarrow {
  border-radius: 8px;
  color: #070707;
  border: 0.5px solid #d8d8d8;

  height: 35px;

  display: flex;
  justify-content: space-between;
  place-items: center;
}

.kitchdiv_arrow_padding {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 10px;
}

.tree_service_arrow_padding {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.info-i {
  border: 2px solid;
  border-radius: 29px;
  line-height: 0px;
  padding-right: 6px;
  font-size: 10px;
  margin: 4px;
  padding-left: 6px;
}

.typeofTxt.tree-service-input .paintupDownheight {
  width: 100% !important;
}

.text_width:first-letter {
  text-transform: uppercase;
}

.kitchenplusimg {
  width: 13px;
  height: 15px;
  padding-top: 4px;
}

.paddingleft8 {
  padding-left: 8px;
}

.sumhead {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.sumhead1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
}

.floor_other_sub_header {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  /* or 150% */
  padding-top: 10px;

  color: #000000;
}

.tblwidth {
  place-items: center;
  text-align: center;
  width: 100%;
}

.paddingLeft10 {
  padding-left: 10px;
}

.treatimg {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 105px;
  border-radius: 16px;
}

.paddingbottom5 {
  padding-bottom: 5px;
}

.paddingbottom2 {
  padding-bottom: 2px;
}

.floor_input_border {
  border-bottom: 0.5px solid #d8d8d8;
  border: none;
  width: 70%;
}

.treatimg1 {
  width: 110%;
  height: 120px;
  border-radius: 16px;
}

.floorSqftcolor {
  padding: 10px;
}

.floor_placeholder_right {
  margin-left: 70%;
  font-style: italic;
  font-weight: bold;
  font-size: 13px;
  line-height: 24px;
  color: #adadad;
  font-family: Poppins;
  padding-top: 52px;
  position: absolute;
}

.floor_placeholder_right::placeholder {
  margin-left: 10px;
}

.floorExclamtxt {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #d26c0d;
  background: #ffead7;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 20px;
  border-radius: 8px;
}

.fullPadding06 {
  padding: 6px;
}