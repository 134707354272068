.w4rAnimated_checkmark svg {
    display: block;
  }
  .w4rAnimated_checkmark .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
  }
  .w4rAnimated_checkmark .path.circle {
    -webkit-animation: dash 0.9s ease-in-out;
    animation: dash 0.9s ease-in-out;
  }
  .w4rAnimated_checkmark .path.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
    animation: dash 0.9s 0.35s ease-in-out forwards;
  }
  .w4rAnimated_checkmark .path.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
    animation: dash-check 0.9s 0.35s ease-in-out forwards;
  }
  
  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  @-webkit-keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
  
  
  
  
  .animation-ctn{
    text-align:center;
    margin-top:5em;
  }
  
      @-webkit-keyframes checkmark {
      0% {
          stroke-dashoffset: 100px
      }
  
      100% {
          stroke-dashoffset: 200px
      }
  }
  
  @-ms-keyframes checkmark {
      0% {
          stroke-dashoffset: 100px
      }
  
      100% {
          stroke-dashoffset: 200px
      }
  }
  
  @keyframes checkmark {
      0% {
          stroke-dashoffset: 100px
      }
  
      100% {
          stroke-dashoffset: 0px
      }
  }
  
  @-webkit-keyframes checkmark-circle {
      0% {
          stroke-dashoffset: 480px
     
      }
  
      100% {
          stroke-dashoffset: 960px;
        
      }
  }
  
  @-ms-keyframes checkmark-circle {
      0% {
          stroke-dashoffset: 240px
      }
  
      100% {
          stroke-dashoffset: 480px
      }
  }
  
  @keyframes checkmark-circle {
      0% {
          stroke-dashoffset: 480px 
      }
  
      100% {
          stroke-dashoffset: 960px
      }
  }
  
  @keyframes colored-circle { 
      0% {
          opacity:0
      }
  
      100% {
          opacity:100
      }
  }
  
  /* other styles */
  /* .svg svg {
      display: none
  }
   */
  .inlinesvg .svg svg {
      display: inline
  }
  
  /* .svg img {
      display: none
  } */
  
  .icon--order-success svg polyline {
      -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
      animation: checkmark 0.25s ease-in-out 0.7s backwards
  }
  
  .icon--order-success svg circle {
      -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
      animation: checkmark-circle 0.6s ease-in-out backwards;
  }
  .icon--order-success svg circle#colored {
      -webkit-animation: colored-circle 0.6s ease-in-out 0.7s backwards;
      animation: colored-circle 0.6s ease-in-out 0.7s backwards;
  } 
  .page-load-err {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .page-load-err__icon {
    width: 32px;
    height: 32px;
    /* animation: rotate 1s linear infinite; */
    transform-origin: center center;
    display: inline-block;
  }
  .icon-loader {
    fill: #01b2ac;
  }
  .icon-alert {
    fill: #da291c;
  }
  .page-load-err__text {
    font-family: Helvetica, sans-serif;
    text-align: center;
    color: #333;
    margin: 20px auto;
    font-size: 18px;
  }
  
  @keyframes rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  
  