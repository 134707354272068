.icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .icon {
    flex: 0 0 auto;
    padding: 5px;
    display: block;
    fill: #000;
    stroke: #000;
  }
  
  .icon svg { 
    --size: 3em;
    
    fill: inherit;
    min-height: var(--size);
    min-width:  var(--size);
  }