.mb8{
    margin-bottom: 8px;

}
.mb16{
    margin-bottom: 16px;

}
.mb24{
    margin-bottom: 24px;

}
.mb32{
    margin-bottom: 32px;

}
.mb40{
    margin-bottom: 40px;

}
.mb48{
    margin-bottom: 48px;

}


.mr8{
    margin-right: 8px;

}
.mr16{
    margin-right: 16px;

}
.mr24{
    margin-right: 24px;

}
.mr32{
    margin-right :32px;

}
.mr40{
    margin-right: 40px;

}
.mr48{
    margin-right: 48px;

}
