.shapes-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sevice_section {
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* align-items: center;  */
  justify-content: center;
}
.link {
  font-family: Poppins;
  font-style: regular;
  font-size: 12px;
  color: #5b75ff;
  cursor: pointer;
}
.floor_placeholder_right {
  margin-left: 370px;
  font-style: italic;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #adadad;
  padding-top: -57px;
  position: absolute;
}
.sevice_title {
  font-family: Poppins;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}
.sevice_section p {
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
}
.sub_heading {
  margin-top: -13px;
}
.sub_buttons {
  font-family: Poppins;
  border: none;
  border-radius: 10px;

  grid-gap: 11px;
  width: 45vh;
  border: 1px solid #d8d8d8;
  display: flex;
  text-align: left;
  margin-top: 8px;
  padding: 21px 22px;
  font-style: normal;
  cursor: pointer;
}
.button {
  width: 33vh;
}
.blue_button {
  font-family: Poppins;
  border: none;
  border-radius: 10px;
  width: 45vh;
  grid-gap: 11px;
  background-color: #5b75ff;
  border: 1px solid #d8d8d8;
  display: flex;
  text-align: left;
  margin-top: 8px;
  padding: 5px 22px;
  color: white;
  align-items: center;
  cursor: pointer;
}

.dialog_section {
  width: 45vh;
  height: 45vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}
.dialog_title1 {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 400;
}
.blue_head {
  color: #2ccae8;
}
.main_buttons {
  font-family: Poppins;
  border: none;
  border-radius: 10px;
  grid-gap: 11px;
  box-shadow: 11px 11px 11px rgba(0, 0, 0, 0.07);
  border: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: space-between;

  text-align: left;
  margin-top: 8px;
  padding: 2px 10px;
}
.main_button_left {
  display: flex;
  align-items: center;
}
.main_button_right {
  border-left: 1px solid #d8d8d8;
  padding: 5px;
}
.sub_title {
  font-weight: 400;
  font-size: 16px;
  font-family: Poppins;
}

@media screen and (max-width: 600px) {
  .button {
    width: 30vh;
    cursor: pointer;
  }
}
