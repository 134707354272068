:root {
  --heights: 84vh;
  --widths: 100%;
}

.slider-container {
  height: var(--heights);
  width: var(--widths);
  position: relative;
  margin: auto;
  overflow: hidden;
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.slides {
  height: var(--heights);
  width: var(--widths);
  position: relative;
}

.slide-image {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  border-radius: 20px;
}

@media only screen and (min-width: 100px) and (max-width: 2000px) {
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 282px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

/* 2778x1284 pixels at 458ppi */
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 282px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }

}

/* 2340x1080 pixels at 476ppi */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

/* 2532x1170 pixels at 460ppi */
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

/* 1792x828px at 326ppi */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {

  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

/* 2436x1125px at 458ppi */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 282px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

/* 2688x1242px at 458ppi */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

.slide-title,
.slide-text {
  width: 100%;
  height: 100%;
  color: white;
  font-size: 50px;
  position: absolute;
  text-align: center;
  top: 40%;
  z-index: 10;
}

.slide-text {
  top: 65%;
  font-size: 2rem;
}

.prev,
.next {
  cursor: pointer;
  z-index: 100;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 1rem;
  margin-top: -3rem;
  font-size: 28px;
  font-weight: bold;
  border-radius: 0px 5px 5px 0px;
  color: white;
}

.prev:hover,
.next:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.5s ease-in;
}

.next {
  right: 0;
  border-radius: 5px 0px 0px 5px;
}

.all-dots {
  /* width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    top: 85%;
    justify-content: center;
    z-index: 200; */
  width: 100%;
  z-index: 9999;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 10px;
  text-align: center;
}

.dot {
  cursor: pointer;
  height: 9px;
  width: 9px;
  margin: 0px 3px;
  background-color: #D8D8D8;
  border-radius: 50%;
  display: inline-block;
}

.dot.overHamburger {
  position: absolute;
  top: 0px;
  left: -4px;
  background: #ff5b33;
}

.dot.overLink {
  background: #ff5b33;
  margin-top: 10px;
}

.active-dot,
.dot:hover {
  background-color: #0ABDE1;
}

.dot.overHamburger:hover,
.dot.overLink:hover {
  background: #ff5b33;
}

/* @media screen and (max-width: 600px) {
   :root{
    --heights: 45vh;
   }
   .dot {
    cursor: pointer;
    height:3%;
    width: 3%;
    margin: 0px 3px;
   
    border-radius: 50%;
    display: inline-block;
   }
  } */
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    /* margin-top: -3rem; */
    margin-top: -20px;
    /* font-size: 28px; */
    font-size: 22px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width : 321px) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -20px;
    font-size: 22px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width : 320px) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

/**********
  iPad 3
  **********/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width : 1224px) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

/* Large screens ----------- */
@media only screen and (min-width : 1824px) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

/* iPhone 4 ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

/* iPhone 6, 7, 8 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

/* iPhone 6+, 7+, 8+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

/* iPhone X ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

/* iPhone XS Max, XR ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

/* Samsung Galaxy S3 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

/* Samsung Galaxy S4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

/* Samsung Galaxy S5 ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {

  /* Styles */
  .slides {
    height: auto;
    position: relative;
  }

  .slide-image {
    height: 310px;
    border-radius: 8px;
  }

  .slider-container {
    height: 40vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    color: white;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
}