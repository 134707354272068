@keyframes growProgressBar {
  0%,
  33% {
    --pgPercentage: 0;
  }
  100% {
    --pgPercentage: var(--value);
  }
}

@property --pgPercentage {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

div[role="progressbar"] {
  --size: 5rem;
  --fg: #2ccae8;
  --bg: #fff;
  --pgPercentage: var(--value);
  animation: growProgressBar 1s 1 forwards;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: grid;
  place-items: center;
  background: radial-gradient(
      closest-side,
      white 80%,
      transparent 0 99.9%,
      white 0
    ),
    conic-gradient(var(--fg) calc(var(--pgPercentage) * 15%), var(--bg) 0);
  font-family: Helvetica, Arial, sans-serif;
  font-size: calc(var(--size) / 5);
  color: var(--fg);
  margin-right: 13px;
  border: 1px solid #2ccae8;
  box-sizing: border-box;
  left: 42.05%;
  right: 44.25%;
  top: 35.21%;
  bottom: 36.43%;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 34px;
}

div[role="progressbar"]::before {
  counter-reset: percentage var(--value);
  content: counter(percentage) "";
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 22px;
  padding-left: 22px;
  border: 1px solid #2ccae8; border-radius: 50%;
 
}
