@import "~bootstrap/scss/bootstrap";

.add-address-form {
  p,
  label {
    color: #000;
    font-size: 14px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    text-align: left;
  }
  label {
    margin-bottom: 10px;
  }
  input[type="text"] {
    border-radius: 10px;
    height: 46px;
    border: 1px solid #858585;
    background: #fff;
    font-size: 14px;
    font-family: "Poppins";
    color: #000;
    width: 100%;
    padding: 0px 20px;
    &::placeholder {
      font-size: 14px;
      font-family: "Poppins";
      color: #858585;
      font-style: normal;
      padding: 0;
    }
  }

  .input-large {
    height: 80px;
  }
  .error-message {
    color: #ff0000;
    margin-left: 5px;
    margin-top: 5px;
  }
}
