.menuheader {
  font-weight: 700;
  font-size: 20px;
  margin: 0 0 -18px 0;
}

.project-details-header {
  display: flex;
  justify-content: space-between;
}
