.stepper-body {
  display: flex;
  background: #fefefe;
  border: 0.5px solid #d8d8d8;
  box-sizing: border-box;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  height: 100px;
}

.stepper-dialog {
  max-width: 400px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  padding-top: 50px;
  overflow: hidden;
  width: calc(100vw - 70px);
}

.stepper-dialog-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  text-align: center;
  color: #000000;
  margin-top: 15px;
}

.stepper-dialog-footer {
  margin: 32px 0px;
  display: flex;
  justify-content: space-between;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  width: 90%;
  padding: 0px 50px;
  /* identical to box height */

  text-align: center;

  /* Main Color Light */

  color: #2ccae8;
}

.KitchenModelClass .stepper-dialog {
  border-radius: 20px;
  padding: 15px 10px;
  overflow: hidden;
  max-width: 600px;
}

.KitchenModelClass .stepper-dialog img {
  margin: 15px auto 35px !important;
  display: block;
}

.KitchenModelClass .MuiDialog-paper {
  margin: 0 !important;
}

.stepper-dialog-text {
  padding: 0 !important;
}

.stepper-dialog-footer > div {
  cursor: pointer;
}
