@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.paint-home-section {
  padding: 20px;
}

#tbl_roomSize {
  table-layout: fixed;
  width: 100%;
}

.width50 {
  width: 50%;
  /*20% width for 5 td elements*/
}

.paddingLeft20 {
  padding-left: 20px;
}

.project-full-address {
  margin: 30px 0 0 0;
}

.check-in-text {
  color: #5fca5d;
}

.project-label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: #000000;
  text-align: start;
  margin: 0 0 7px 0;
}

.paint_flex_with_center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.paint_flex_with_left {
  display: flex;
  justify-content: left;
  align-items: left;
  width: 100%;
  margin: 0;
}

.padding30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.paddding_2035 {
  padding-bottom: 35px;
  width: 100%;
}

.arrow_icon {
  margin-top: 7px;
  margin-left: -57px;
}

.paint_header {
  font-family: Poppins;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.paint_header2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #030303;
}

.paint-section-projects {
  height: 282px;
  width: 100%;
  left: 0px;
  top: 0px;
  border-radius: 8px;
}

.paint-section-projects.paint-optn {
  height: 300px;
  object-fit: cover;
  border-radius: 10px
}

.paint_img_bottom_title_first {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}

.paint_img_bottom_title_last {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
}

.paint_other_header {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #000000;
}

.paint_other_sub_header {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  padding-top: 10px;
  padding-bottom: 10px;

  color: #000000;
}

.paint_home_form_btn {
  display: flex;
  align-items: center;
  border: none;
  background: #2ccae8;
  border-radius: 20px;
  text-align: center;
  padding: 20px 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  color: #fffefe;
  margin-bottom: 20px;
}

.paint_radio_div {
  border-radius: 8px;
  color: #070707;
  border: 0.5px solid #d8d8d8;
  padding: 5px;
  margin-top: 10px;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
}

/* 2340x1080 pixels at 476ppi */
.header_path_name_show {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  font-family: Poppins;
  color: #5b75ff;
}

.radio_btn_right {
  float: right;
}

.div_radiobtn_paddingright {
  padding-right: 20px;
}

.cursor_hand {
  cursor: pointer;
}

.paint_main_buttons {
  font-family: Poppins;
  border: none;
  border-radius: 10px;
  width: 100%;
  grid-gap: 11px;
  box-shadow: 11px 11px 11px rgba(0, 0, 0, 0.07);
  border: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: space-between;

  text-align: left;
  margin-top: 35px;
}

.paint_main_button_left {
  display: flex;
  align-items: center;
  margin-left: 13px;
}

.paint_main_button_left .progressBar {
  height: 80px;
}

.paint_main_button_right {
  display: flex;
  align-items: center;
  border-left: 1px solid #d8d8d8;
  padding: 15px;
}

.paint_every_flow_title {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;

  color: #000000;
}

.paint_every_price_txt {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;

  color: #000000;
}

.paintdiv_txt {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #030303;
}

.paintdiv_radiobtn_paddingright {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.paint_radio_div_first {
  border-radius: 8px;
  color: #070707;
  border: 0.5px solid #d8d8d8;

  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paint_paddingtop {
  padding-top: 30px;
}

.paint_radio_div_firstarrow {
  border-radius: 8px;
  color: #070707;
  border: 0.5px solid #d8d8d8;

  height: 45px;

  display: flex;
  justify-content: space-between;
  place-items: center;
}

.paintdiv_arrow_padding {
  padding: 10px;
}

.paint_arrowFloat {
  float: right;
}

.floatLeft {
  float: left;
}

.paint_arrowHeight {
  height: 22px;
}

.paint_arrowpadd_bottom {
  padding-bottom: 5px;
}

.paintPadding20 {
  padding-left: 20px;
  padding-right: 20px;
}

.textleft {
  text-align: left;
}

.paintsuffix {
  padding-top: -130px;
  left: 0;
  top: 3px;
  color: rgb(85, 85, 85);
  padding-left: 5px;
}

.paintinput_lable {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  display: flex;
  margin-top: 30px;
  color: #000000;
}

.paint_input_border {
  border-bottom: 0.5px solid #d8d8d8;
  border: none;
}

.paint_placeholder_right {
  margin-left: -80px;
  font-style: italic;
  font-weight: bold;
  font-size: 13px;
  line-height: 24px;
  color: #adadad;
  font-family: Poppins;
  padding-top: 47px;
}

.paint_table100 {
  width: 100%;
}

.paintroomsize_tableheader {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: left;
  color: #000000;
  font-weight: bold;
}

.paddingtop30 {
  padding-top: 30px;
}

.padding15 {
  padding-top: 15px;
  padding-right: 10px;
}

.paddingight10 {
  padding-right: 10px;
  padding-top: 10px;
}

.padditopt10 {
  padding-top: 10px;
}

.paint_home_section {
  font-family: Poppins;
  border: none;
  border-radius: 10px;

  grid-gap: 11px;
  border: 1px solid #d8d8d8;
  text-align: left;
  font-style: normal;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
}

.paintdiv_roomsizeTitle1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;

  color: #030303;
}

.paintdiv_roomsizeTitle2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #030303;
}

.paintdiv_roomsizeTitleright {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;

  color: #030303;
}

.padding5 {
  padding: 15px;
}

.paint_div_radioBtn {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  margin-top: 15px;
  color: #2ccae8;
}

.paint_room_div_height {
  height: 80px;
}

.paint_radio_div_roomsize {
  border-radius: 8px;
  color: #070707;
  border: 0.5px solid #d8d8d8;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 27.5px;
  padding-bottom: 27.5px;
}

.paint_radio_div_roomsize.brdrpx1 {
  border: 1px solid #d8d8d8 !important;
}

table#tbl_roomSize {
  border-spacing: 10px;
}

table#tbl_roomSize td {
  /* border: 1px solid #d8d8d8 !important; */
  position: relative;
  padding: 0 !important;
  border-radius: 11px;
}

table#tbl_roomSize td .paint_radio_div_roomsize.brdrpx1 {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
}

table#tbl_roomSize td .paint_radio_div_roomsize.brdrpx1 label {
  line-height: 20px;
}

.add_another_roomBtn {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  padding-left: 10px;
  color: #2ccae8;
  font-family: Poppins;
}

.paddingtop50 {
  padding-top: 40px;
}

.inputDropdown {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  font-family: Poppins;
  color: #adadad;
}

/* Optional styles for when the select is open. Doesn't work on all browsers */
option {
  color: black;
}

.grayout {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #adadad;
  border: none;
}

select {
  margin: 0;
  vertical-align: top;
  padding: 5px;
  background-image: url("../../_assets/images/other-images/droparrowVector.png");
  background-position: right 0px center;
  background-repeat: no-repeat;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-appearance: none;
  font-size: inherit;
  overflow: hidden;
  font-family: Poppins;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
  width: 100%;
  /* background: white; */

  /* background-size: 12.73px 7.78px; */
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

/* .paintdiv_radiobtn_paddingright {
  padding: 10px;
} */
.picColorroom1_radioHeader {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.padding_8 {
  padding-right: 15px;
}

.picColorroom1_txtHeader {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.picColorroom1_txtplaceHolder {
  font-family: Poppins;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #adadad;
}

input ::placeholder {
  font-family: Poppins;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #adadad;
  padding-left: 10px;
}

.paint_primercoat {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;

  color: #000000;
}

.paintPadding8 {
  padding-top: 8px;
}

.paintpaddingtop30 {
  padding-top: 30px;
}

.paintpaddingtop25 {
  padding-top: 25px;
}

.tdwidth {
  width: 100%;
}

.marginRight {
  text-align: right;
}

.marginRight1 {
  margin-left: 30px;
  padding-top: 6px;
}

#my_table {
  table-layout: fixed;
  width: 100%;
}

.width33 {
  width: 33%;
}

.paint_radio_div_first1 {
  border-radius: 8px;
  color: #070707;
  border: 0.5px solid #d8d8d8;
  place-items: center;
  display: flex;
}

.flexWidth {
  display: flex;
  width: 100%;
}

.paddingAddons {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 30px;
  padding-right: 30px;
}

/* .paddingleft8
{
  padding-left: 8px;
} */
.summaryInterior {
  border-radius: 8px;
  color: #070707;
  border: 0.5px solid #d8d8d8;
  box-sizing: border-box;
  justify-content: space-between;
}

.flexPaint {
  display: flex;
  width: 100%;
}

.gridPaint {
  display: grid;
}

.sum td {
  width: 20%;
}

.summaryDivheader1 {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #030303;
  font-family: Poppins;
}

.summaryDivheader2 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding-left: 10px;
  font-family: Poppins;
}

.contactblue_btn {
  font-family: Poppins;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 4px 4px 8px rgb(0 0 0 / 10%);
  grid-gap: 11px;
  /* background-image: url("../../_assets/images/other-images/polygon.png"); */
  background-color: #5b75ff;
  /* border: 1px solid #d8d8d8; */
  display: flex;
  text-align: left;
  margin-top: 8px;
  padding: 5px 22px;
  color: white;
  align-items: center;
  height: 80px;
}

.condactNeedhelp {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
}

.contactuslink {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  text-decoration-line: underline;
  color: #ffffff;
}

.conductPadding {
  padding-top: 80px;
}

.paintsummaryCommands {
  background: #fefefe;
  border: 0.5px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 16px;
  height: 165px;
  width: 100%;
}

.message::placeholder {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b5b5b5;
  padding: 15px;
}

.commandspadding {
  padding-top: 10px;
}

.exteriorDivpadding {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 30px;
  padding-right: 30px;
}

.paintexterior ::placeholder {
  font-family: Poppins;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #adadad;
}

.paint_placeholder_right1 {
  margin-left: -80px;
  font-style: italic;
  font-weight: bold;
  font-size: 13px;
  line-height: 24px;
  color: #adadad;
  padding-top: 41px;
  font-family: Poppins;
}

/* .fenceLable {
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 18px;

color: #858585;

} */
.otherspadding25 {
  padding-top: 25px;
}

.fenceLable {
  margin-left: -571px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  font-family: Poppins;
  color: #858585;
  padding-top: 18px;
}

.typeofTxt {
  border: none;
  height: 50px;
  padding-top: 20px;
}

.paintdiv_radiobtn_padding {
  padding-left: 18px;
  padding-top: 26px;
  padding-right: 26px;
  padding-bottom: 26px;
}

.paintupDownheight {
  border: none;
  height: 20px;
}

.paintDropdownarrowposition {
  margin-left: -30px;
}

.otherspadding15 {
  height: 50px;
  margin-top: 11px;
}

.painterror {
  border: 1px solid red;
}

.paintErrorspan {
  padding-top: 10px;
  color: red;
}

.paintPersonalinfo_div {
  background: #e9fbff;
  border-radius: 8px;
}

.paint_flex_with_center_personal {
  text-align: center;
  padding: 25px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-family: Poppins;
  /* Black */

  color: #030303;
}

.paintPolygon {
  margin-left: 99%;
  margin-top: -81px;
}

table input.paintupDownheight {
  width: 50% !important;
}

table input.paintupDownheight.full-width {
  width: 100% !important;
}

table .paint_arrowHeight.cursor_hand {
  position: relative !important;
  z-index: 999 !important;
}