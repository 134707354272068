.floor-body {
  font-family: Poppins;
  padding: 20px;
}

.floor-sub-type {
  width: 100%;
}

.floor-sub-type .bear-react-carousel__root {
  height: 500px !important;
  border-radius: 8px;
}

/* .floor-sub-type #bear-react-carousel_fed99e38-59e0-4860-a486-f8005b0ebf49 {
  height: 500px;
} */

.floor-sub-type .bear-react-carousel__content {
  border-radius: 8px;
}

.floor-sub-type .bear-react-carousel__slide-item__div {
  background-position: center;
  width: 100%;
  border-radius: 8px;
}

.floor-title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #5b75ff;
}

.floor-sub-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #000000;
}

.floor-selection-radio {
  display: flex !important;
  align-items: center;
  background: #fefefe;
  border: 1.5px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px 30px;
  justify-content: space-between;
  margin-bottom: 15px;
  overflow: hidden;
  width: 100%;
  min-height: 60px;
  cursor: pointer !important;
}
@media screen and (max-width: 992px) {
  .floor-selection-radio {
    padding: 0 10px;
  }
}
.floor-selection-radio label {
  cursor: pointer !important;
}

.floor-selection-radio.selected {
  border: 1.5px solid #2ccae8;
}

.FloorTitle.pc-width {
  width: calc(100% - 170px) !important;
}

.floor-selected {
  display: flex;
  align-items: center;
  background: #fefefe;
  border: 0.5px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 8px;
  min-height: 75px;
  padding: 26px 32px;
  justify-content: space-between;
  margin-top: 8px;
  overflow: hidden;
}

.floor-selected .bold {
  text-align: left;
}

.floor-selection-radio:hover {
  border: 1.5px solid #2ccae8;
}

.floor-selection-radio-img {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 7px 32px;
}

.floor-button {
  margin-top: 20px;
  background: #2ccae8;
  border-radius: 15px;
  width: 100%;
  padding: 19px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  font-family: Poppins;
  outline: none;
  border: none;
}

.floor-button:disabled {
  background: #d8d8d8;
  color: #adadad;
}

.m-t-32 {
  margin-top: 32px;
}

.sun-image {
  height: 70px;
  width: 70px;
  margin-left: -17px;
}

.wish-text {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  text-align: "left";
  /* margin-right: "30px", */
}

.wish-time {
  color: #adadad;
  font-size: 12px;
  margin-right: 30px;
}

.blue_btn {
  font-family: Poppins;
  border: none;
  border-radius: 8px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  grid-gap: 11px;
  background-color: #5b75ff;
  /* border: 1px solid #d8d8d8; */
  display: flex;
  text-align: left;
  margin-top: 8px;
  padding: 5px 22px;
  color: white;
  align-items: center;
  height: 80px;
  width: calc(100% - 60px);
  position: relative;
}

.blue_btn::after {
  content: "";
  display: block;
  width: 15px;
  height: 70px;
  background-image: url(./../../_assets/images/other-images/polygon.png);
  background-repeat: no-repeat;
  position: absolute;
  right: -12px;
  top: 12px;
}

.blue_btn img,
.contactblue_btn img {
  position: relative;
  border: 6px solid #fcbfac;
  border-radius: 100%;
  display: block;
  width: 45px;
  height: 45px;
}

.blue_btn img {
  width: 55px;
  height: 55px;
}

.blue_btn div:nth-child(1),
.contactblue_btn div:nth-child(1) {
  background: #f9dcd3;
  border-radius: 100%;
}

.blue_btn div:nth-child(2) {
  height: 75px;
  text-align: left;
  padding-top: 25px;
}

.blue_btn div:nth-child(2) p:nth-child(1) {
  margin: 0;
  text-align: left;
  font-size: 14px;
}

.blue_btn div:nth-child(2) p:nth-child(2) {
  font-weight: 700;
  text-decoration: underline;
  text-align: left;
  margin: 0;
  font-size: 14px;
}

.blue_btn img::after,
.blue_btn img::before {
  content: "";
}

.startDate-container {
  margin: 13px;
}

.carpettxtinfo {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;

  color: #000000;
}

.contractor-project-details .floor-selected .price-wrp {
  display: none !important;
}

.contractor-project-details .label-sub-price-text {
  display: none !important;
}

/*************************************************************************************/
/* .radio_div {
  border-radius: 8px;
  color: #070707;
  border: 0.5px solid #d8d8d8;
  padding: 10px;
  margin-top: 20px;
  box-sizing: border-box;
  font-family: "Poppins";
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
}
.link {
  font-family: "Poppins";
  font-style: regular;
  font-size: 12px;
  color: #5b75ff;
}
.sevic_title {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}
.sevice_section p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.sub_hdg {
  margin-top: 7px;
  font-family: "Poppins";
}
.sub_btn {
  font-family: Poppins;
  border: none;
  border-radius: 10px;

  grid-gap: 11px;

  border: 1px solid #d8d8d8;
  display: flex;
  text-align: left;
  margin-top: 8px;
  padding: 21px 22px;
}
.blue_btn {
  font-family: Poppins;
  border: none;
  border-radius: 10px;

  grid-gap: 11px;
  background-color: #5b75ff;
  border: 1px solid #d8d8d8;
  display: flex;
  text-align: left;
  margin-top: 8px;
  padding: 5px 22px;
  color: white;
  align-items: center;
}

.dialog_section {
  width: 45vh;
  height: 45vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}
.dialog_title1 {
  font-family: "Poppins";
  font-size: 20px;
}
.blue_head {
  color: #2ccae8;
}
.main_button {
  font-family: Poppins;
  border: none;
  border-radius: 10px;

  grid-gap: 11px;
  box-shadow: 11px 11px 11px rgba(0, 0, 0, 0.07);
  border: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: space-between;

  text-align: left;
  margin-top: 8px;
  padding: 2px 10px;
} */
