.country-select {
  background-color: #fff;
  border-bottom: 0.5px solid #d8d8d8;
  font-size: 12px;
  padding: 5px 0px 10px 10px;
}
.custom-phone-field label {
  font-size: 12px;
  font-weight: 700;
}

.custom-phone-field .input-text.is-not-valid {
  color: red !important;
}

.input-text.is-valid .country-select {
  color: green !important;
  border-bottom-color: green;
}
