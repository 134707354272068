.profile_stepmenPadd {
  float: right;
  padding-right: 40px;
}

.profileTablewidth active {
  width: 100%;
}

.profile_main_buttons {
  border: none;
  border-radius: 10px;
  width: 100%;
  height: 100px;
  grid-gap: 11px;
  box-shadow: 11px 11px 11px rgba(0, 0, 0, 0.07);
  border: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: space-between;

  text-align: left;
  margin-top: 25px;
}

.profileFlex {
  /* display: grid;
     */

  width: 100%;
}

table {
  width: 100%;
}

.td1 {
  line-height: 0;
}

.td2 {
  /* padding-left: 40%; */
  vertical-align: middle;
}

.td3 {
  float: right;
  margin-right: 20px;
}

.profilediv {
  background: #fefefe;
  /* Line color light */
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border: 0.5px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 8px;
  place-items: center;
  position: relative;
  margin-bottom: 5px;
}

.profiledivSub {
  background: #fefefe;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  height: 60px;
  box-sizing: border-box;
  border-radius: 0;
  margin: 5px auto;
}

.padding_top8 {
  padding-top: 8px;
}

.profileDivtxt {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.profileDivmargin {
  height: 60px;
  width: 60px;
  border-radius: 8px;
  line-height: 0;
}

.profileDivmargin2 {
  /* margin-top: 20px;
  position: absolute; */
  padding-left: 20px;
}

.profileDivmargin3 {
  /* margin-top: -50px;
  margin-right: 20px;
  text-align: right; */
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -10px;
  line-height: 0;
}

.profileDivmarginchk {
  margin-top: -47px;
  margin-right: 20px;
  text-align: right;
  position: absolute;
}

.profilePadding10 {
  padding-top: 10px;
}

.profilePadding8 {
  padding-top: 8px;
}

.profilePadding5 {
  padding-top: 5px;
}

.profilePadding15 {
  padding-top: 15px;
}

.profilePadding25 {
  padding-top: 25px;
}

.profilePadding20 {
  padding-top: 20px;
}

.profilePadding30 {
  padding-top: 30px;
}

.profilePadding40 {
  padding-top: 40px;
}

.profileConfirmationback {
  background-color: #2ccae8;
  padding-top: 1%;
  padding-bottom: 20%;
}

.profileConfirmationtitle {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 28px;
  text-align: center;

  color: #fefefe;
}

.padding100 {
  padding-top: 100px;
}

.personal_flex_with_center {
  text-align: center;
  width: 100%;
}

.profileBtn1 {
  font-family: Poppins;
  background: #fefefe;
  border-radius: 15px;
  height: 65px;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #2ccae8;
}

.profileBtn2 {
  border: 1px solid #fefefe;
  text-align: center;
  box-sizing: border-box;
  border-radius: 15px;
  height: 65px;
}

.profileClose {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.profileOpen {
  display: block;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.open {
  display: block;
}

.close {
  display: none;
}

.borderRound {
  border: 1px solid #2ccae8;
  padding: 10px;
  border-radius: 50%;
}

.pecilBackground {
  text-align: center;
  background: #2ccae8;
  height: 25px;
  width: 25px;
  padding: 30px;
  border-radius: 50%;
}

.profileTextarea {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.profile_placeholder_right {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #000000;
  position: absolute;
  right: 5px;
  bottom: 5px;
  width: 55px;
}

.profileUploadlabel {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 16px;
  color: #000000;
}

.borderDash1 {
  border: 1px dashed #d8d8d8;
  border-radius: 8px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 35px;
  padding-left: 35px;
}

.browseLbl {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #2ccae8;
  cursor: pointer;
}

.browseValidationlbl {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #000000;
}

.paddingRight {
  padding-left: 5px;
}

.uploadPicimg {
  border: 1px dashed #d8d8d8;
}

.uploadwidthHeight {
  width: 115px;
  height: 115px;
}

.uploadPicpadd {
  font-family: Poppins;
  padding-top: 30px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  display: grid;
  text-align: center;
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
}

.paddbott20 {
  font-family: Poppins;
  padding-bottom: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;

  color: #000000;
}

.allCenter {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  /* NEW, Spec - Firefox, Chrome, Opera */

  justify-content: center;
  align-items: center;
  place-items: center;
}

.txtalignCenter {
  text-align: center;
}

.procomTxt {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #858585;
}

.procomTxt1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #000000;
}

.wronImg {
  width: 23.82px;
  height: 23.82px;
}

.tickImg {
  width: 23.82px;
  height: 23.82px;
}

.padding10per {
  padding-right: 30px;
}

.terms_and_coditionpro {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* color: #ADADAD; */
}

.chckbox {
  /* position: absolute; */
  padding-top: 20px;
}

.inputfile {
  /* visibility: hidden etc. wont work */
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile:focus+label {
  /* keyboard navigation */
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile+label * {
  pointer-events: none;
}

.paddingleft {
  padding-left: 10px;
}

.confirmationTitle {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 28px;
  text-align: center;
  color: #fefefe;
}

.confirmationTitle1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #fefefe;
}

.profileDocfont {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  display: grid;
}

.allCenterGrid {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: grid;
  /* NEW, Spec - Firefox, Chrome, Opera */
  place-items: center;
  justify-content: center;
  align-items: center;
}

.contactor_profile_image.stable-class {
  display: table;
  margin: 0 auto;
  position: relative;
}

.contactor_profile_image.stable-class {
  display: table;
  margin: 0 auto;
  position: relative;
}

.profile_image.stable-class {
  display: table;
  margin: 0 auto;
  width: 185px;
  float: none;
  height: 25px;
  position: relative;
}

.profile_edit_button {
  position: absolute;
  right: 0;
  top: 0;
}

.profile_visible_image {
  width: 100%;
  display: block;
  line-height: 0;
}

.profile_image img {
  width: 170px;
  height: 170px;
  display: block;
  overflow: hidden;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
  padding: 5px;
  border: 1px solid #2cc9e8;
}

.profile_edit_button span {
  width: 55px;
  height: 55px;
  padding: 0;
  border: none;
  background-image: url('./../../_assets/images/other-images/pencil.png');
  background-color: #2cc9e8;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px;
  display: block;
  border-radius: 100%;
}

.personal_flex_with_center {
  text-align: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}




.profileDocfont.allCenterGrid {
  border: 2px dashed #d1d1d1;
  padding: 10px;
  width: 75px;
  height: 75px;
  border-radius: 10px;
  margin: 0 auto;
}

.personal_flex_with_center {
  text-align: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  /* justify-content: center;   */
}

.personal_flex_with_center.profilePadding10 td {
  line-height: 0;
}

.personal_flex_with_center.profilePadding10 td img {
  width: 99px;
  height: 99px;
  border-radius: 15px;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
}

.personal_flex_with_center.profilePadding10>label {
  margin: 0 auto;
}

.personal_flex_with_center.profilePadding10 td+label {
  margin: unset;
}

.browseLbl_blk {
  display: block;
  width: 100%;
}

.personal_flex_with_center.details_wrp.flex_Wrp #imageWrapper+.browseLbl_blk {
  width: auto;
}

.personal_flex_with_center.details_wrp.flex_Wrp #imageWrapper .uploadwidthHeight {
  width: 100px;
  height: 100px;
}

.profilediv.profile_complete_box {
  border: 1px solid #e4e4e4;
  margin-bottom: 1px;
}

.foot_term_condition .terms_and_coditionpro {
  font-size: 16px;
}

table.flex.foot_term_condition tr td.chckbox {
  line-height: 0;
}

table.flex.foot_term_condition tr td.chckbox .roles [type="checkbox"] {
  width: 25px !important;
  height: 25px !important;
  margin-left: 10px;
}

.managerLabel_name {
  position: absolute;
  left: 0;
  bottom: 10px;
  width: calc(100% - 65px);
  overflow: hidden;
  text-overflow: ellipsis;
}

.managerLabel_name span {
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}

.YourWrk_wrp {
  display: block;
  margin-bottom: 50px;
}

.YourWrk_wrp .personal_flex_with_center.profilePadding10>img.uploadwidthHeight {
  width: 99px;
  height: 99px;
  object-fit: cover;
  object-position: center;
}

.YourWrk_wrp .personal_flex_with_center.profilePadding10 img+label,
.details_wrp #imageWrapper+.upload_image_sec {
  margin: unset !important;
}

.gradient {
  width: 115px;
  height: 115px;
  position: absolute;
  z-index: 9999;
}

.gradient:hover {
  border-radius: 8px;
  background: linear-gradient(225deg, rgba(2, 0, 36, 1) 0%, rgba(101, 101, 101, 0) 100%, rgba(255, 255, 255, 1) 100%);
}

.gradient.removing {
  border-radius: 8px;
  background: rgba(101, 101, 101, 50%);
}

.gradient span {
  display: none;
}

.gradient.removing span {
  border-radius: 8px;
  background: rgba(101, 101, 101, 50%);
  z-index: -9999;
}

.gradient:hover span {
  display: block;
  color: white;
  font-weight: 600;
  float: right;
  margin-right: 8px;
  cursor: pointer;
}

.gradient.removing:hover span {
  display: none;
}