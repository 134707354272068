.delete-account {
  background: #a8a4a4;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.delete-container {
  max-width: 650px;
  background-color: #fff;
  padding: 70px;
  font-family: "Poppins";
  font-style: normal;
  margin: 50px;
}

.delete-info h1 {
  font-weight: 600;
  font-size: 22px;
  color: #000000;
}

.delete-info p {
  font-weight: 400;
  font-size: 14px;
  text-align: left;
}

.delete-type-text {
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 30px;
}

.delete-input {
  background: #ffffff;
  border: 1px solid #858585;
  border-radius: 10px;
  margin-bottom: 24px;
  padding: 15px;
}

.delete-input:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.delete-request-btn {
  background: #ffffff;
  border: 1px solid #2ccae8;
  border-radius: 10px;
  width: 100%;
  height: 46px;
  color: #2ccae8;
  font-weight: 500;
  font-size: 14px;
}

.delete-otp-text {
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  margin: 24px 0px;
}

.timer {
  color: #2ccae8;
  font-weight: 400;
  font-size: 14px;
}

.delete-otp-text span,
.delete-type-text span {
  font-weight: 800;
}

.delete-btn {
  background: #2ccae8;
  border-radius: 10px;
  height: 46px;
  width: 50%;
  margin-left: 10px;
  margin-top: 30px;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  border: none;
}
.delete-btn:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}
.delete-cancel-btn {
  background: #ffffff;
  border: 1px solid #858585;
  border-radius: 10px;
  height: 46px;
  margin-right: 10px;
  width: 50%;
  margin-top: 30px;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

@media screen and (max-width: 992px) {
  .delete-container {
    padding: 30px;
    margin: 20px;
  }
}
