@keyframes growProgressBar {
  0%,
  33% {
    --pgPercentage: 0;
  }
  100% {
    --pgPercentage: var(--value);
  }
}

@property --pgPercentage {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

div[role="progressProfile"] {
  --size: 110px;
  --fg: #2ccae8;
  --bg: #fff;
  /* --fg: #fff;
  --bg: #2ccae8; */
  --pgPercentage: var(--value);
  animation: growProgressBar 1s 1 forwards;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: flex;
  text-align: center;
  background: radial-gradient(
      closest-side,
      var(--fg) 80%,
      transparent 0 99.9%,
      var(--fg) 0
    ),
    conic-gradient(var(--fg) calc(var(--pgPercentage) * 100%), var(--fg) );
  
  color: var(--bg);
  border: 1px solid #2ccae8;
  left: 42.05%;
  right: 44.25%;
  top: 35.21%;
  bottom: 36.43%;
  font-family: Poppins;
font-style: normal;
font-weight: bold;
font-size: 35px;
/* identical to box height */


/* Neutral */

color: #FEFEFE;
  box-sizing: border-box;
  display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
          /* NEW, Spec - Firefox, Chrome, Opera */
  padding-top: 15px;
  place-items: center;
  
}

div[role="progressProfile"]::before {
  counter-reset: percentage var(--value);
  content: counter(percentage) "%";
  /* content: "Profile complete"; */
}
.profileComtxt{
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  padding-bottom: 20px;
  color: #FEFEFE;
  
}
.centerjustified {
  text-align: justify;
  text-align-last: center;
}
.divOutborder
{
  border-radius: 50%;
  padding: 20px;
  background: #A6F0FF;
  box-sizing: border-box;
  height: 150px;
  width: 150px;
  -webkit-animation: blinkingText 3s infinite;
  -moz-animation: blinkingText 3s infinite; 
  -o-animation: blinkingText 3s infinite;
  animation: blinkingText 3s infinite; 
}

@keyframes blinkingText{

0%		{ background-color: #A6F0FF;}
25%		{ background-color: #2ccae8;}
50%		{ background-color: #A6F0FF;}
75%		{ background-color: #2ccae8;}
100%	{ background-color: #A6F0FF;}
}
.procenter
{
  display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
          /* NEW, Spec - Firefox, Chrome, Opera */
  
  place-items: center;
}