.app-notification .notificationsTitleWrapper {
    margin-bottom: 0;
    padding-bottom: 15px;
    border-bottom: none
}

.app-notification .notificationsBlock {
    min-height: 110px;
    height: auto;
}

.app-notification .notificationsBlock {
    background: #EBFCFF;
    border-bottom: none;
}

.app-notification .notificationsBlock.read {
    background: #ffffff;
}

.app-notification .notificationsBlock.read .notificationsDot,
.app-notification .notificationsBlock.read .notificationsImage {
    background: #afafaf;
}

.app-notification .notificationsMessage {
    padding-right: 7px 10px 7px 0px;
}

.app-notification .notificationsImage {
    min-width: 40px;
    height: 40px;
}

.app-notification .notificationsImage img {
    width: 15px;
}

.line {
    background: #959595;
    border-radius: 0;
    height: 1px;
}