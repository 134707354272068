.carousel {
  overflow: hidden;
  position: relative;
  height: 265px;
  z-index: 10;
}

.inner {
  white-space: nowrap;
  transition: transform 0.6s;
}

.custom-carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 265px;
  width: 100%;
}
.custom-carousel-item img {
  width: 100%;
  height: 100%;
}
.indicators {
  display: flex;
  justify-content: center;
}

.car-img {
  object-fit: cover;
  z-index: 1;
}

.indicators > button {
  margin: 5px;
}

.indicators .custom-carousel-controls {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  z-index: 0;
}
.custom-carousel-controls.prev {
  left: 0;
}
.custom-carousel-controls.next {
  right: 0;
}
.indicators > button.active {
  background-color: green;
  color: #fff;
}

.dots {
  position: absolute;
  bottom: 20px;
  overflow-x: scroll;
  width: 70px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
}

.dots button {
  border: 1px solid #fff;
  background-color: transparent;
  border-radius: 50%;
  margin: 0px 5px;
  height: 8px;
  width: 8px;
  padding: 0;
}
.dots button.active {
  background-color: #fff;
}
.dots::-webkit-scrollbar {
  display: none;
}
.custom-carousel-controls {
  display: none;
}

.carousel-count {
  position: absolute;
  right: 24px;
  bottom: 22px;
  z-index: 10;
  color: white;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 109px; */
  height: 20px;
  border-radius: 50px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  padding: 0px 5px;
}

@media screen and (max-width: 992px) {
  .custom-carousel-controls {
    display: block;
  }
}
.carousel:hover .custom-carousel-controls {
  display: block;
}

button.custom-carousel-controls.hide {
  display: none !important;
}

@media screen and (max-width: 768px) and (min-width: 520px) {
  .custom-carousel-item,
  .carousel {
    height: 400px;
  }
}

@media screen and (min-width: 768px) {
  .portfolio-carousel .custom-carousel-item,
  .portfolio-carousel .carousel {
    height: 500px;
  }
}

@media screen and (max-width: 768px) {
  .portfolio-carousel .custom-carousel-item,
  .portfolio-carousel .carousel {
    height: 350px;
  }
}

@media screen and (min-width: 768px) {
  .carrousels .custom-carousel-item,
  .carrousels .carousel {
    height: 80vh;
    max-height: 500px;
  }
}
