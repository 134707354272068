/* .tabs
{
    width: 80%;
} */
/* .tabs .tab-header
{
    height: 60px;
    display: flex;
    align-items: center;
} */
.tabs {
  margin: 0 auto;
  display: table;
}

.tabs .tab-header {
  height: 20px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.tabs .tab-header>div {
  /*  width: calc(100%/3); */
  text-align: center;
  outline: none;
  cursor: pointer;
  padding-left: 2px;
  padding-right: 2px;
}

.tabs .tab-header>div.active {
  color: #2CCAE8;
}

/* .tabs .tab-indicator
{
    margin-top: -15px;
    position: relative;
    width: calc(100%/3);
    place-items: center;
    background: #2CCAE8;
    height: 3px;
    left: 0px;
    border-radius: 100px;
    transition:all 500ms ease-in-out;
} */

.tabs .tab-indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #2ccae8;
  transform-origin: 0 0;
  transition: transform 0.25s;
}

.paddingleftright25 {
  margin-left: 10%;
  margin-top: -15px;
  margin-right: 49%;
}

.menuheadertxt {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

.tabs .tab-body {
  position: relative;
}

.tabs .tab-body>div {

  opacity: 0;
  transform: scale(0.9);
  transition: opacity 500ms ease-in-out 0ms,
    transform 500ms ease-in-out 0ms;
}

.tabs .tab-body>div.active {
  top: 0px;
  opacity: 1;
  transform: scale(1);
}

.divborderMyproject {
  border: 0.5px solid #D8D8D8;
  box-sizing: border-box;
  border-radius: 16px 0px 0px 0px;
}

.myproject_radio_div_first1 {
  place-items: center;
  display: flex;
}

.divborderMyprojectbottom {
  border: 0.5px solid #D8D8D8;
  box-sizing: border-box;
  border-radius: 0px 0px 16px 16px;
}

.myprojecttxtblue {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #5B75FF;
}

.myprojecttxtblue .pending {
  color: orange;
}

.approvalTxt.pending {
  color: orange;
}

.approvalTxt.completed {
  color: #5fca5d;
}

.projectdetailsHeader {
  font-family: Abhaya Libre ExtraBold;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.approvalTxt {
  font-family: Tauri;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
}

.dateTxt {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #ADADAD;
}

.dateTxt.project_date {
  display: block;
  text-align: center;
}

.divtxtNormal {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  text-align: center;
  margin: 0 10px;
}

.myprojectdivBorder {
  border: 1px dashed #D8D8D8;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px;
}

.menuheadertxt {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 16px;
  color: #000000;
}

.menuheaderSubtxt {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: #000000;

}

.editviewBlue {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;

  color: #2CCAE8;
}

.smileTxt {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  color: #000000;
}