.btn-primary {
  display: block;
  width: 100%;
  border: none;
  background-color: #2ccae8;
  position: static;
  left: 0%;
  right: 25.86%;
  top: 0%;
  bottom: 0%;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  text-align: center;
  color: #ffffff;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  border-radius: 15px;
}

.btn-primary:disabled {
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  text-align: center;

  /* Place holder */

  color: #adadad;

  background: #d8d8d8;
  border-radius: 15px;
}

.btn-white {
  display: block;
  width: 100%;
  border: 1px solid #2CCAE8;
  background-color: #ffffff;
  position: static;
  left: 0%;
  right: 25.86%;
  top: 0%;
  bottom: 0%;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  text-align: center;
  color: #2CCAE8;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  border-radius: 15px;
}

.btn-primary-border {
  display: block;
  width: 100%;
  border: 1px solid #ffffff;
  background-color: #2ccae8;
  position: static;
  left: 0%;
  right: 25.86%;
  top: 0%;
  bottom: 0%;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  text-align: center;
  color: #ffffff;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  border-radius: 15px;
}

.btn-checkout {
  display: block;
  width: 100%;
  border: 1px solid #ffffff;
  background-color: #5B75FF;
  position: static;
  left: 0%;
  right: 25.86%;
  top: 0%;
  bottom: 0%;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  text-align: center;
  color: #ffffff;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  border-radius: 15px;
}

.btn-checkouttick {
  display: block;
  width: 100%;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  position: static;
  left: 0%;
  right: 25.86%;
  top: 0%;
  bottom: 0%;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  text-align: center;
  color: #2ccae8;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  border-radius: 15px;
}

.allCenterbtn {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  /* NEW, Spec - Firefox, Chrome, Opera */
  justify-content: right;
  align-items: right;
  place-items: center;
}

.btn-plus {
  display: block;
  width: 100%;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  position: static;
  left: 0%;
  right: 25.86%;
  top: 0%;
  bottom: 0%;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  text-align: center;
  color: #2ccae8;
  padding: 14px 8px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  border-radius: 15px;
}

.btn-cancel-project {
  color: #D80336;
}