.assistant-project-title {
  font-family: Poppins;
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  line-height: 2;
  padding: 0 17px 0 17px;
}

.assistant-project-inputs {
  margin: 33px 0 0 0;
}

.assistant-project-inputs input {
  width: 90%;
  margin: 0 0 0 17px;
}

.assistant-id {
  margin: 32px 0 0 0;
  text-align: start;
}

.project-name {
  font-size: 12px;
  line-height: 18px;
}

.project_upload_document button {
  width: 100%;
  border: 1px solid #2ccae8;
  background-color: white;
  height: 65px;
  border-radius: 15px;
  margin: 32px 0 0 0;
  font-size: 18px;
  color: #2ccae8;
  font-weight: 700;
  font-family: Poppins;
}

.add-new-assistant {
  color: #2ccae8;
  font-weight: 700;
  font-size: 18px;
  margin: 50px 0 0 0;
}

.project-primary-disable {
  background-color: #d8d8d8;
  color: #adadad;
}

.project-primary {
  background-color: #2ccae8;
  color: white;
}
.project-save-changes {
  width: 100%;
  height: 65px;
  margin: 115px 0 0 0;
  border: 1px solid #d8d8d8;
  border-radius: 15px;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 700;
}
.project-cancel {
  color: #d80336;
  font-weight: 700;
  font-size: 18px;
  margin: 45px 0 0 0;
  cursor: pointer;
}

.three-lines-wrap {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.contractor-project .check_in {
  color: #5fca5d;
}
.contractor-project .confirm {
  color: #5b75ff;
}

.tentative-details {
  padding: 0 !important;
}

.assistant-ctn {
  padding: 0 !important;
  margin: 10px 0 0 0;
}

@media all and (max-width: 700px) {
  .tentative-radio-buttons {
    width: 95%;
  }
  .project-save-changes {
    width: 95%;
  }
  .mobile-align-flex-centre {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
