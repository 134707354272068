input {
  width: 88%;
  border: 0;
  border-bottom: 0.5px solid #D8D8D8;
  border-color: #D8D8D8;
  outline: 0;
  height: 32px;
  font-size: 16px;
  line-height: 32px;
  font-style: italic;
  color: black;
}

.label {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
}

.input-text {
  width: 308px;
  border-color: rgb(245, 14, 14);
  font-size: 16px;
}

input.\,input-text\,is-not-valid {
  border-bottom: 1px solid red;
}
.input-text.is-not-valid {
  color: red;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
}
.input-text.is-not-valid ::placeholder {
  color: red;
}

.flex-justify-fix .signup_validation_password_icon {
  display: -moz-flex !important;
  display: -webkit-flex !important;
  display: -ms-flex !important;
  display: flex !important;
  -moz-justify-content: flex-end !important;
  -webkit-justify-content: flex-end !important;
  -ms-justify-content: flex-end !important;
  justify-content: flex-end !important;
}
.flex-justify-fix .signup_tick_icon {
  display: -moz-flex !important;
  display: -webkit-flex !important;
  display: -ms-flex !important;
  display: flex !important;
  -moz-justify-content: flex-end !important;
  -webkit-justify-content: flex-end !important;
  -ms-justify-content: flex-end !important;
  justify-content: flex-end !important;
}
