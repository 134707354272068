.home_Wrapper .pc-img {
    display: none !important;
}

.insideWrp .mobile_menu.overHamburger img.mL10.app-img {
    display: none;
}

.foot-list span {
    display: block;
}

.paintinput_lable.mb40.kitgrid.border-btm-cls {
    position: relative;
    border-bottom: 1px solid #d8d8d8;
}

.paintinput_lable.mb40.kitgrid.border-btm-cls .floor_placeholder_right {
    bottom: 15px;
}

.home_Wrapper .headerMain .headerTitle,
.home_Wrapper .signup_text {
    color: #ffffff;
}

.headerTitle img {
    display: none;
}

.home_Wrapper img.homeLogo {
    display: block;
}

.insideWrp img.innerLogo {
    display: block;
}

.nav {
    height: 100% !important;
}

@media only screen and (max-width: 769px) {

    .home_servce_grid.show-few-services .homeService_Wrp.stable_class>div:nth-child(7),
    .home_servce_grid.show-few-services .homeService_Wrp.stable_class>div:nth-child(8),
    .home_servce_grid.show-few-services .homeService_Wrp.stable_class>div:nth-child(9),
    .home_servce_grid.show-few-services .homeService_Wrp.stable_class>div:nth-child(10),
    .home_servce_grid.show-few-services .homeService_Wrp.stable_class>div:nth-child(11),
    .home_servce_grid.show-few-services .homeService_Wrp.stable_class>div:nth-child(12),
    .home_servce_grid.show-few-services .homeService_Wrp.stable_class>div:nth-child(13),
    .home_servce_grid.show-few-services .homeService_Wrp.stable_class>div:nth-child(14) {
        display: none;
    }

}

@media only screen and (max-width: 769px) {

    /* html, body{
        display: none;
    } */
    .signup_text {
        color: #2ccae8 !important;
    }

    .home_Wrapper .signup_text {
        color: #ffffff !important;
    }

    ul.footer-list {
        margin-left: 20px !important;
        padding-inline-start: 0;
    }

    ul.footer-list li {
        display: block !important;
        margin-left: 0 !important;
    }

    .BearSlider.bathroomSlide,
    .BearSlider.floorSlide {
        height: 310px !important;
    }

    .BearSlider.bathroomSlide+table {
        margin-bottom: 30px;
        margin-top: 5px;
    }

    .BearSlider.floorSlide+.floor-selection-radio-img.imgtxt {
        margin-bottom: 55px;
        margin-top: 15px;
    }

}

@media only screen and (min-width: 769px) {
    .home_Wrapper img.homeLogo {
        display: none;
    }

    .home_Wrapper img.innerLogo {
        display: block;
    }

    .mobile_menu {
        position: relative;
    }

    .home_Wrapper .headerMain .headerTitle {
        color: #2ccae8;
    }

    .home_Wrapper .signup_text {
        color: #2ccae8 !important;
    }

    .home_Wrapper .pc-img {
        display: block !important;
    }

    .home_Wrapper .app-img {
        display: none !important;
    }

    .termsMainWrapper {
        text-align: center;
    }

    .nav {
        height: 100% !important;
        top: 0 !important;
        left: unset !important;
        right: 0 !important;
        bottom: 0 !important;
        /* transition: all 0.8s; */
        /* transform: scale(0); */
        transition: all 0.7s ease-in-out !important;
        transform: translateX(100%) !important;
        max-width: 392px !important;
        box-shadow: 0px 0px 8px 1px #e4e4e4;
    }

    #toggle:checked+.hamburger+.nav {
        top: 0% !important;
        transform: translateX(0) !important;
    }

    .btn-primary.quartid-btn {
        background-color: #2ccae8;
        color: #fff;
    }

    .home-section,
    .home-section1,
    .floor-body.smooth,
    .pcWidth,
    .profileConfirmationback>.paint-home-section,
    .insideWrp .headerMain {
        max-width: 1200px !important;
        margin: 0 auto !important;
    }

    .home-section1 {
        margin-bottom: 80px !important;
    }

    .insideWrp .home-section,
    .insideWrp .floor-body.smooth,
    .pcWidth,
    .profileConfirmationback>.paint-home-section,
    .insideWrp .headerMain {
        max-width: 535px !important;
        padding: 16px !important;
    }

    .paintPcWidth.pcWidth {
        max-width: 475px !important;
    }

    .insideWrp.pc-767 .pcWidth,
    .insideWrp.pc-767 .headerMain,
    .insideWrp.pc-767 .floor-body.smooth {
        max-width: 767px !important;
    }

    .homeService_Wrp>div {
        width: 174px !important;
        margin-bottom: 15px;
    }

    .treatimg {
        height: 145px !important;
    }

    .MainFooter {
        width: calc(100% + 50px);
        position: relative;
        left: -25px;
    }

    .MainFooter .flex .left_div {
        width: calc(100% - 75px);
        height: auto;
    }

    .MainFooter .flex .right_div {
        width: 75px;
        padding-bottom: 70px;
    }

    .foot-list {
        margin: 0 auto;
    }

    .copy-text {
        text-align: center;
        display: block;
        width: calc(100% - 95px);
        margin-left: 0 !important;
    }

    .foot-list span {
        display: inline-block;
    }

    .home-section1 .tyh.grid {
        display: flex;
    }

    .hamburger {
        cursor: pointer;
    }

    .hamburger:hover {
        color: #2ccae8;
    }

    .sqft_hide_function .Initial_hide {
        display: none;
    }

    .sqft_hide_function .paint_input_border.input-text+.Initial_hide {
        display: block;
    }

    .widthAddcls td.width33:first-child {
        width: 250px;
    }

    .FloorpcWidth .FloorTitle {
        width: 300px !important;
    }

    .SummeryTitle {
        width: 300px !important;
    }

    .titleWidth {
        width: 270px !important;
    }

    .profileConfirmationback {
        position: relative;
        z-index: 99;
    }

    .profileConfirmationback::after {
        content: '';
        width: calc(100% + 50px);
        height: calc(100% + 25px);
        left: -25px;
        top: -20px;
        background: #2ccae8;
        position: absolute;
        z-index: -1;
    }

    .chatTitleWrapper svg {
        margin-right: 0px !important;
    }

    .eachImage_block {
        width: 25%;
    }

    .eachImage_block span {
        height: 165px;
    }

    .floor-option {
        display: flex;
        justify-content: space-between;
        /* gap: 10px; */
        margin-left: -5px;
        margin-right: -5px;
    }

    .floor-option .floor-selection-radio {
        width: 100%;
        margin-left: 5px;
        margin-right: 5px;
    }

    .grid-slider,
    .solid-grid-slider {
        /* display: flex;
        gap: 10px;
        flex-wrap: wrap; */
        /* justify-content: space-between; */
        display: block;
        float: left;
        margin: 0 auto;
    }

    .grid-slider .kitchhome-section-projects {
        /* width: calc( 33.33% - 10px );
        margin-bottom: 10px; */
        max-height: 200px;
        width: 245px;
        margin-bottom: 10px;
        padding-right: 5px;
        padding-left: 5px;
        float: left;
    }

    .grid-slider {
        display: -moz-flex !important;
        display: -webkit-flex !important;
        display: -ms-flex !important;
        display: flex !important;
        flex-wrap: wrap;
    }

    .grid-slider .kitchhome-section-projects.pading_top_cls {
        overflow: hidden;
    }

    .solid-grid-slider>label {
        /*  max-height: 200px;
        width: calc( 33.33% - 10px );
        margin-bottom: 10px; */
        max-height: 200px;
        width: 245px;
        margin-bottom: 10px;
        padding-right: 5px;
        padding-left: 5px;
        float: left;
    }

    .solid-slider {
        height: 150px !important;
    }

    .solid-slider img {
        width: 100%;
        height: 100% !important;
        object-fit: cover;
        object-position: center;
    }

    .grid-slider .bear-react-carousel__pagination-button,
    .solid-grid-slider .dot {
        width: 5px;
        height: 5px;
    }

    .grid-slider .bear-react-carousel__pagination-group {
        bottom: 5px;
    }

    .pc_txt {
        width: calc(100% - 150px) !important;
    }

    .pc_txt_150 {
        width: calc(100% - 170px) !important;
        /* text-transform: lowercase !important; */

    }

    .pc_txt_150:first-letter {
        text-transform: uppercase
    }

    .pc-width {
        max-width: 300px !important;
    }

    .bet_style {
        max-width: 575px;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 769px) {
    .home-section1 {
        margin: 40px auto;
    }
}



.pc_txt_150::first-letter {
    text-transform: uppercase;
}

.chat-warpper {
    position: absolute;
    width: 100%;
}

.chat-warpper.show {
    z-index: 99999;
    display: block;
}

.chat-warpper.hide {
    z-index: 0;
    display: none;
}