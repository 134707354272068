.home_servce_grid.tblwidth{
  width: calc(100% + 40px);
  margin-left: -20px;
}
.home_servce_grid .width33:nth-child(1){
  padding-left: 20px;
  padding-right: 0;
}
.home_servce_grid .width33:nth-child(2){
  padding-left: 10px;
  padding-right: 10px;
}
.home_servce_grid .width33:nth-child(3){
  padding-left: 0;
  padding-right: 20px;
}
.home_servce_grid .width33 a {
  display: block;
}
.home_servce_grid .width33 .treatimg{
  box-shadow: 3px 3px 7px 0 #e0e0e0;
  height: 115px;
}
.home_servce_grid .width33 .allCenter{
  line-height: 20px;
}
.paint_main_buttons .paint_main_button_right tr td img {
  width: 50px;
}
.work img{
  width: 13px;
}
.signup_section .signup_editBtn img {
  width: 13px;
  height: 13px;
}
.home-section-projects .slider-container{
  border-radius: 0 0 8px 8px;
}


.tblwidth.home_servce_grid.paddinngtop20.stable_class, .home_servce_grid.tblwidth.stable_class{
  width: calc(100% + 40px);
  margin-left: -20px;
  padding-left: 10px;
  padding-right: 10px;
}

.homeService_Wrp {
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.homeService_Wrp > div{
  width: 33.33%;
  display: block;
  float: left;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.homeService_Wrp > div{
  padding-bottom: 7px;
}
.homeService_Wrp > div:nth-child(odd){
  padding-left: 7.5px;
  padding-right: 7.5px;
  
}
.homeService_Wrp > div:nth-child(even){
  padding-left: 7.5px;
  padding-right: 7.5px;
}
