html,
body {
  scroll-behavior: smooth;
}

/* for default arrow hide start */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

button {
  cursor: pointer;
}

.floor-selection-radio input[type="radio"],
.radio_20 input[type="radio"] {
  width: 20px;
  height: 20px;
}

.floor-selection-radio input[type="radio"]::before,
.radio_20 input[type="radio"]::before {
  width: 14px;
  height: 14px;
  margin: 2px;
}

.paint_input_border input::placeholder {
  padding-left: 0;
}

.paint_input_border input {
  padding-left: 10px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.paint_input_border {
  width: 100%;
}

div[role="progressbar"] {
  min-width: 80px;
  max-width: 80px;
  width: 80px;
}

.width100 > label.paintdiv_txt {
  width: calc(100% - 30px);
  display: block;
  float: left;
}

.plusPadngFix
  .kitchendiv_radiobtn_paddingright
  .width100
  .paint_arrowFloat
  label.paintdiv_txt.paddingleft8 {
  position: relative;
  top: -5px;
}

.plusPadngFix .width100 > label.paintdiv_txt {
  width: calc(100% - 130px);
}

.pading_top_cls .padding2050 {
  padding-bottom: 20px;
}

.stable_class {
  display: block;
  position: relative;
  float: left;
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.paddinngtop20.space-adjust
  + .paintPadding8
  div
  .width100
  > label.paintdiv_txt:first-child {
  margin-bottom: 15px;
}

.paddinngtop20.space-adjust
  + .paintPadding8
  div
  .width100
  > label.paintdiv_txt {
  width: 100%;
}

.star {
  position: absolute;
  margin-top: -2px;
  right: -1px;
  top: 0px;
}

.bg-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.slider-wrp.bg-img {
  background-position: right -15px;
}

.layout_content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  padding-top: 0;
}

.checkout-slider-container.tranformplus > section {
  border-radius: 0;
  overflow: hidden;
}

.slide_arrow .arrows .prev,
.slide_arrow .arrows .next {
  margin-top: -45px;
}

.checkout-slider-container.tranformplus.slide_arrow {
  height: auto;
}

.checkout-slider-container.tranformplus.slide_arrow + .btn-checkouttick,
.btn-checkouttick.contact-btn {
  margin: 5px auto 25px;
  border: 1px solid #e4e4e4;
  border-radius: 0px 0px 12px 12px;
}

.btn-checkouttick.contact-btn + div.allCenter {
  padding-bottom: 20px;
}

.checkoutslide-image-gradient.tranformmius table tr td {
  vertical-align: bottom;
}

/* My-project page css*/

.tabs .tab-header {
  position: relative;
}

.tabs .tab-indicator {
  width: 100% !important;
}

.menuheadertxt:nth-child(2) {
  margin-left: 10px;
  margin-right: 10px;
}

.active.menuheadertxt ~ .tab-indicator {
  transform: translateX(0) scaleX(0.28);
}

.menuheadertxt:nth-child(2).active ~ .tab-indicator {
  transform: translateX(31.333%) scaleX(0.333);
}

.menuheadertxt:nth-child(3).active ~ .tab-indicator {
  transform: translateX(calc(34.333% * 2)) scaleX(0.29);
}

.menuheadertxt {
  font-weight: normal;
  transition: 0.5s all;
}

.active.menuheadertxt {
  font-weight: bold;
  transition: 0.5s all;
}

/* My-project page css*/

/* portfolio */

.pln_btn {
  text-align: center;
  display: table;
  margin: 20px auto;
}

.pln_btn label {
  color: #2ccae8;
  font-size: 18px;
  font-weight: bold;
}

.folio_rate.stable_class {
  margin-bottom: 30px;
}

.folio_label_wrp {
  background: #edfcff;
  padding: 20px 15px;
  border-radius: 15px;
  color: #2cc9e8;
  text-align: center;
  padding-left: 45px;
  background-image: url("./../../_assets/images/other-images/personalvector.png");
  background-position: 15px center;
  background-size: 30px;
  background-repeat: no-repeat;
  margin-bottom: 30px;
}

.folio_label span {
  font-weight: bold;
}

.star_count {
  display: flex;
  gap: 2px;
  justify-content: center;
  align-content: center;
}

.star_count p {
  margin: 0;
  line-height: 1;
  padding: 0;
}

.folio_rate .star_count p {
  color: #ff6187;
}

.star_value,
.None_value {
  font-size: 56px;
  font-weight: bold;
  color: #ff6187;
  margin: 0 auto;
  display: table;
  line-height: 1;
}

.None_value {
  font-size: 14px;
}

.star_label {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  display: block;
  margin-top: 5px;
  color: #ff6187;
}

.auther_img {
  width: 125px;
  height: 125px;
  display: block;
  margin: 0 auto;
  border-radius: 100%;
  overflow: hidden;
}

.auther_img img,
.banner_img img,
.eachImage_block span img {
  display: block;
  width: 100%;
  object-position: center;
  object-fit: cover;
  height: 100%;
}

.folio_review_con span + label {
  text-align: center;
  display: block;
  font-size: 14px;
  font-weight: bold;
  padding: 7px 0;
}

.folio_review_con .star_count p {
  color: #2cc9e8;
}

.review_discription {
  width: 75%;
  display: table;
  margin: 0 auto;
  font-size: 15px;
  padding: 15px 0 20px;
}

.folio_review_con:last-child {
  padding-bottom: 45px;
}

.folio_review_wrp .paint_header {
  font-size: 16px;
}

/* portfolio detail */
.portfolio_detail_banner {
  height: 265px;
  line-height: 0;
}

.banner_img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.back_arrow_btn {
  position: absolute;
  padding: 5px;
  z-index: 9999;
  width: 35px;
  left: 20px;
  top: 20px;
}

.portfolio_detail_banner .slide-image {
  border-radius: 0;
}

.portfolio_detail_wrp {
  padding: 10px 5px;
  margin-bottom: 15px;
}

.portfolio_image_grid.stable_class {
  display: flex;
  flex-wrap: wrap;
}

.eachImage_block {
  width: 33.33%;
}

.eachImage_block span {
  padding: 5px;
  display: block;
  height: 115px;
}

.eachImage_block span img {
  border-radius: 15px;
  overflow: hidden;
}

.contact_detail-sec.stable_class {
  padding: 20px 15px;
}

.contact_detail-sec .review_discription {
  width: 100%;
  text-align: justify;
  padding-bottom: 5px;
  padding-top: 5px;
}

.contact_detail-sec.stable_class .paint_header {
  margin-bottom: 15px;
  display: block;
}

.contact_detail-sec .review_discription:last-child {
  padding-bottom: 25px;
}

.allCenterbtn.arrow_gap .btn-checkout span {
  padding-right: 10px;
}

.allCenterbtn.arrow_gap .btn-checkout img {
  position: relative;
  top: 2px;
  width: 20px;
}

.editButton-cls {
  border: 0.5px solid #d8d8d8;
  border-top-color: transparent;
  border-radius: 0 0 8px 8px;
  position: relative;
  top: -8px;
  background: #fff;
}

.editButton-cls::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 1px;
  width: calc(100% - 30px);
  background: #d8d8d8;
}

.justified-txt p {
  text-align: left;
  text-align: justify;
  margin-bottom: 10px;
  margin-top: 0;
  font-size: 14px;
}

.justified-txt p:last-child {
  margin-bottom: 0;
}

.paint_main_button_right table tr td {
  text-align: center;
}

.height_auto .floor-selection-radio {
  height: auto;
}

.text_width span {
  display: block;
}

.plcehlder_align::placeholder {
  margin: 0 !important;
  padding: 0 !important;
}

.plcehlder_align {
  padding-top: 20px;
}

.home_section_wrp .home-section-projects .all-dots {
  bottom: 25px;
}

.btn-primary.quartid-btn {
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #2ccae8;
  background: #fff;
}

.myProfile_wrapper .floor-selected {
  min-height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.myProfile_wrapper .floor-selected span {
  display: block;
}

.rd_bg {
  background-color: #d80437 !important;
}

.btn-checkouttick.contact-btn + div.allCenter {
  display: none;
}

.btn-checkouttick.contact-btn.tick-btn + div.allCenter {
  display: flex;
}

.star.ownStar {
  left: -2px;
  top: 0;
}

.star.ownStar label {
  color: #ffffff;
}

.star_slider .bear-react-carousel__slide-item {
  border-radius: 10px 10px 0 0;
}

.checkoutslide-image-gradient.ownSlide {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 100%;
}

/* ❮ ❯ */
.bear-react-carousel__nav-prev-button,
.bear-react-carousel__nav-next-button {
  background: transparent !important;
}

.bear-react-carousel__nav-icon {
  position: relative;
  background: none !important;
}

.bear-react-carousel__nav-icon::after {
  content: "❯";
  font-size: 22px;
}

/* .bear-react-carousel__nav-prev-button .bear-react-carousel__nav-icon::after{
    content: '❮';
}
.bear-react-carousel__nav-next-button .bear-react-carousel__nav-icon::after{
    content: '❯';
} */
.star_slider {
  height: 225px !important;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.btn-checkouttick.btn-checkoutBtn {
  margin: 5px auto 25px;
  border: 1px solid #e4e4e4;
  border-radius: 0px 0px 12px 12px;
}

.BearSlider {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 5px;
}

.slider-container.Home_Proj_slide .BearSlider {
  margin-left: 0;
  margin-right: 0;
}

.BearSlider .bear-react-carousel__content {
  border-radius: 8px;
}

.slider-container.Home_Proj_slide
  .bear-react-carousel__nav-group
  > .bear-react-carousel__nav-prev-button {
  left: 0;
}

.slider-container.Home_Proj_slide
  .bear-react-carousel__nav-group
  > .bear-react-carousel__nav-next-button {
  right: 0;
}

.star_slider .checkoutTable tr td {
  vertical-align: bottom;
  line-height: 15px;
}

.star_slider .checkoutTable .checkOut-bottomtxt {
}

.contactorBtnSec .contactuslink {
  text-decoration: none;
}

.paint_main_buttons.wallet-btn {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
}

.paint_main_buttons.wallet-btn .paint_main_button_right {
  border: none;
}

.paint_main_buttons.wallet-btn .paint_every_flow_title,
.paint_main_buttons.wallet-btn .paint_every_price_txt {
  font-size: 22px;
  font-weight: bold;
}

.paint_main_buttons.wallet-btn .paint_every_price_txt {
  font-weight: normal;
}

.paint_main_buttons.wallet-btn .paint_main_button_left {
  margin-left: 0;
}

.TextBox.review_discription {
  width: 95%;
}

.TextBox.review_discription p {
  margin-bottom: 0;
}

.absoluteBtn {
  position: absolute;
  right: 15px;
  bottom: 10px;
  z-index: 999;
  color: #2cc9e8;
  font-size: 12px;
}

.approvalTxt {
  font-family: Poppins !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.header_path_name_show.grn-txt,
.header_path_name_show.grn-txt {
  color: #6fcf6d;
}

.header_path_name_show.grn-txt .approvalTxt {
  font-family: Poppins;
}

.divborderMyproject.contractor-poject .myprojecttxtblue .approvalTxt {
  font-size: 14px;
  font-family: Poppins;
}

.divborderMyproject.contractor-poject {
  border-radius: 16px 16px 0 0;
}

.contractor-tabs .active.menuheadertxt ~ .tab-indicator {
  transform: translateX(0) scaleX(0.38);
}

.contractor-tabs .menuheadertxt:nth-child(2).active ~ .tab-indicator {
  transform: translateX(40.35%) scaleX(0.29);
}

.contractor-tabs .menuheadertxt:nth-child(3).active ~ .tab-indicator {
  transform: translateX(calc(36.333% * 2)) scaleX(0.25);
}

.hoverColorChange {
  background: #2ccae8;
  opacity: 0.5;
}

.active-home-icon {
  opacity: 1;
}

.confirm-text {
  color: #5b75ff !important;
}

.hoverColorChange label {
  font-size: 11px;
  color: #ffffff;
  text-align: center;
  display: block;
}

.project-view-header {
  width: 100%;
  height: 48px;
  text-align: start;
  font-weight: 700 !important;
  line-height: 1.8;
  margin: 10px 0 0 0;
  color: #000000 !important;
  width: 100%;
  margin: 0px;
}

.paint_flex_with_left.HeadWithRate div {
  width: 135px;
  text-align: center;
}

.paint_flex_with_left.HeadWithRate {
  justify-content: space-between;
}

.paint_flex_with_left.HeadWithRate div label {
  display: block;
  font-size: 20px;
}

.paint_flex_with_left.HeadWithRate .approvalTxt {
  font-size: 16px;
}

.btn-checkouttick.red-text {
  color: #db1846;
}

/* input.paintupDownheight:disabled{
    background: transparent;
} */
.progress_Dual_digit div[role="progressbar"]::before {
  padding-right: 13px;
  padding-left: 13px;
}

.rltve_cls {
  position: relative;
}

.selectField {
  position: relative;
  flex-direction: column;
}

.paintinput_lable.rltve_cls > i {
  width: 15px;
  height: 15px;
  border: 2px solid #333;
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  border-radius: 100%;
}

.paintinput_lable.rltve_cls .paint_placeholder_right {
  margin-left: -40px;
}

.paintinput_lable.rltve_cls .paint_placeholder_right.tons {
  position: absolute;
  right: 30px;
  margin: 0;
  padding-top: 32px;
}

.paintinput_lable.inline_input .paint_input_border input {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.optionElectric option,
.optionElectric select {
  background-color: transparent;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}

.chatName {
  font-size: 14px;
}

.textInput50 {
  border: none;
  margin: 0 !important;
}

.textInput50 input {
  width: 50%;
  border: 1px solid #e4e4e4;
}

.AddSectionTxt label {
  color: #2ccae8;
  font-weight: bold;
  font-size: 15px;
}

.baathroom_floor .bear-react-carousel__slide-item__div {
  border-radius: 8px;
}

.label-sub-price-text {
  float: right;
  font-size: 14px;
}

.assistantWrapper {
  border: 0.5px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 4px;
  padding: 10px;
  min-height: 75px;
}

.assistantDetails {
  justify-content: space-between;
  align-items: center;
  width: 90%;
  display: flex;
}

.assistantName {
  width: 100%;
  text-align: left;
  text-transform: capitalize;
}

.assistantTitle {
  display: flex;
}

.assistantDocument img {
  max-width: 50px;
  max-height: 50px;
}

.project-list-date-list .date-selected-highlight {
  background: #2ccae8;
  color: #fff;
  height: 10px;
  border-radius: 999px;
  text-align: center;
  line-height: 3px;
}

/* .project-list-date-list .react-calendar__tile--rangeStart {
  background-color: #2ccae8 !important;
  color: #fff !important;
  border-radius: 998px 0px 0px 999px !important;
}
.project-list-date-list .react-calendar__tile--active {
  background-color: #ffff76;
  color: #000000;
  border-radius: 0 0 0 0;
}

.project-list-date-list .react-calendar__tile--rangeEnd {
  border-radius: 0 25px 25px 0;
  color: #fff !important;
  background-color: #2ccae8 !important;
} */
.project-list-date-list {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;
}

.service-banner-sec {
  position: relative;
  height: 280px;
  overflow: hidden;
  width: calc(100% + 40px);
  margin-left: -20px;
  display: flex;
  align-content: space-between;
  flex-direction: column;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  padding: 25px;
  margin-bottom: 25px;
}

.service-banner-sec::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 31.46%, #000000 100%);
}

.banner-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.banner-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -1;
}

.banner-bg.floor-banner img {
  object-position: top center;
}

.service-banner-sec div:not(:first-child) {
  z-index: 99;
  color: #ffffff;
}

.service-banner-sec .floor-title {
  display: none;
}

.service-banner-sec .banner-title label {
  color: #ffffff;
  font-weight: bold;
  font-size: 25px;
}

.modal-image img {
  margin: auto;
  max-width: 95%;
  height: 80%;
}

.modalImageWrapper {
  width: 100%;
  display: flex;
  margin: auto;
  height: 100vh;
}

.modalImageWrapper.portfolioGallery {
  display: -webkit-flex;
  -webkit-align-items: center;
  align-items: center;
}

.modalImageWrapper.portfolioGallery img {
  margin: 0 auto;
  width: auto;
  height: auto;
  max-height: 75vh;
}

select.dropDown {
  background: none;
  -webkit-appearance: auto;
}

.custom-input-for-plumbing {
  width: 100%;
  margin: 0px;
}

.custom-input-for-plumbing input {
  height: 53px;
  border-bottom: none;
  padding-right: 85px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: -5px;
}

.custom-input-for-plumbing .paint_placeholder_right {
  padding-top: 20px;
}

.pic-color tr {
  display: flex;
  justify-content: space-between;
}

.pic-color td.title {
  margin: auto;
  margin-left: 0;
}

.pic-color td.price {
  margin: auto 30px;
}

.pic-color td.check {
  margin: auto 10px;
}

.banner-icon.flex {
  width: 100%;
  padding-top: 10px;
}

.banner-icon.home-bnr-sec.flex {
  padding-top: 10px;
}

.banner-icon.flex div {
  width: 125px;
  height: 65px;
  display: block;
  padding: 0 5px;
}

.banner-icon.flex label {
  color: #000000;
}

.banner-icon.flex div img {
  width: 100%;
  display: block;
  border-radius: 7px;
  object-fit: contain;
}

.bet_style {
  flex-direction: column;
  background-color: #f2f3f3 !important;
  padding-left: 15px;
  padding-right: 15px;
  display: none !important;
}

.bet_style label {
  color: #000000;
}

.bet_style label span {
  display: block;
  text-align: center;
}

.bet_style ul li,
.bet_style label span {
  font-size: 14px;
  font-weight: 500;
}

.captlize-txt {
  text-transform: capitalize !important;
}

.BearSlider.bathroomSlide {
  height: 450px;
}

.floor-selection-radio.dateSelection {
  position: relative;
}

.floor-selection-radio.dateSelection .sun-image {
  margin-left: 0px;
  position: absolute;
  left: 15px;
  top: 12px;
}

.floor-selection-radio.dateSelection .wish-text {
  padding-left: 70px;
}

.bck-btn {
  background: #fff;
  color: #2cc9e8;
  line-height: 20px;
  display: inline-block;
  padding: 3px;
  font-size: 12px;
}

.whtsap-btn {
  display: block;
  width: 80px;
  position: fixed;
  right: 0;
  bottom: 5px;
  z-index: 99999;
}

.home-section-dreammaker {
  display: flex;
}

.dreammakerSlideSection,
.showOffSection {
  width: 50%;
  height: 280px;
}

.dreammakerSlideSection {
  /* padding-right: 15px; */
}

.showOffSection {
  padding-left: 15px;
}

.dreammakerSlideSection .BearSlider .bear-react-carousel__content {
  border-radius: 0px;
}

.dreammakerSlideSection .BearSlider {
  margin: 0 !important;
}

.dreammakerSlideSection .bear-react-carousel__slide-item__div span {
  align-items: end;
  display: flex;
  height: 100%;
  color: #fff;
  padding: 15px;
  box-sizing: border-box;
}

.dreammakerSlideSection div.bear-react-carousel__slide-item__div {
  border-radius: 13px;
  width: calc(100% - 15px);
}

.showoffCardBanner span,
.showoffCardBanner span img {
  display: block;
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 100%;
  border-radius: 10px 10px 0px 0px;
}

.showoffCardBanner span {
  height: 220px;
}

.showoffCardLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  box-shadow: 0px 1px 1px 1px #e4e4e4;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 0px 0px 10px 10px;
}

.showoffCardLabel button {
  width: auto;
  padding: 13px 20px;
  font-size: 14px;
  line-height: 14px;
  border-radius: 10px;
  white-space: nowrap;
}

@media only screen and (max-width: 768px) {
  .banner-icon.home-bnr-sec.flex {
    display: none;
  }

  .mbl_cls {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  .bet_style .pc_cls,
  .pc-btn {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .home-section-dreammaker {
    display: flex;
    flex-direction: column;
  }

  .dreammakerSlideSection,
  .showOffSection {
    width: 100%;
  }

  .dreammakerSlideSection div.bear-react-carousel__slide-item__div {
    width: calc(100% - 10px);
  }

  .showOffSection {
    padding-left: 0px;
    margin-top: 30px;
    height: 100%;
  }

  .showoffCardLabel {
    justify-content: center;
    margin-bottom: 30px;
  }

  .showoffCardLabel .btn-primary {
    display: none;
  }
}

.pc_txt_150::first-letter {
  text-transform: uppercase;
}

.termsMainWrapper h4 {
  font-size: 18px;
}

.termsMainWrapper p {
  font-size: 16px;
  margin-bottom: 10px;
  text-align: left;
}

.termsMainWrapper p b {
  font-weight: bold;
}

.cstm-gradient {
  width: 100%;
  height: 100px;
  background: linear-gradient(to right, #0b98b3,#2ccae8);
  border-radius: 20px;
  padding: 20px;
  display: flex;
}
.icon-container {
  position: relative;
  width: 100px;
  height: 100px;
}

.background-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
  height: 62%;
  background-color: #fff;
  border-radius: 50%;
}

.dollar-symbol {
  position: absolute;
  top: 32%;
  left: 36%;
  transform: translate(-50%, -50%);
  font-size: 38px;
  color: #0b98b3;
}
.amountsection{
  color: #fff;
}


@media only screen and (max-width: 768px) {
  .cstm-gradient {
    width: 100%;
    height: 100px;
    background: linear-gradient(to right, #0b98b3,#2ccae8);
    border-radius: 20px;
    padding: 20px;
    display: flex;
  }
}
@media only screen and (min-width: 768px) {
  .cstm-gradient{
    display: none;
  }
}
