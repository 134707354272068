
  .profileComtxt{
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 18px;
    padding-bottom: 20px;
    color: #FEFEFE;
    
  }
  .centerjustified {
    text-align: justify;
    text-align-last: center;
  }
  .receivedmanOut
  {
    border-radius: 50%;
    background: #A7FFCF;
    place-items: "center";
    height: 330px;
    width: 330px;
    /* -webkit-animation: blinkingText 3s infinite;
    -moz-animation: blinkingText 3s infinite; 
    -o-animation: blinkingText 3s infinite;
    animation: blinkingText 3s infinite;  */
  }
  .receivedman
  {
    border-radius: 50%;
    background: #59FAA3;
    box-sizing: border-box;
    height: 230px;
    width: 230px;
    -webkit-animation: blinkingText 3s infinite;
    -moz-animation: blinkingText 3s infinite; 
    -o-animation: blinkingText 3s infinite;
    animation: blinkingText 3s infinite; 
  }
  @keyframes blinkingText{
  
  0%		{ background-color: #0ce277;}
  25%		{ background-color: #59FAA3;}
  50%		{ background-color: #2be75a;}
  75%		{ background-color: #59FAA3;}
  100%	{ background-color: #A6F0FF;}
  }
  .hangonOut
  {
    border-radius: 50%;
    background: #FFC9B8;
    place-items: "center";
    height: 330px;
    width: 330px;
    
  }
  .hangon
  {
    border-radius: 50%;
    background: #EE9F55;
    box-sizing: border-box;
    height: 230px;
    width: 230px;
    -webkit-animation: blinkingText1 3s infinite;
    -moz-animation: blinkingText1 3s infinite; 
    -o-animation: blinkingText1 3s infinite;
    animation: blinkingText1 3s infinite; 
  }
  @keyframes blinkingText1{
  
  0%		{ background-color: #f7c495;}
  25%		{ background-color: #EE9F55;}
  50%		{ background-color: #ad6e32;}
  75%		{ background-color: #EE9F55;}
  100%	{ background-color: #f87e0c;}
  }
  .procenter
  {
    display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
            /* NEW, Spec - Firefox, Chrome, Opera */
    
    place-items: center;
  }