.all_header_txt {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;

  color: #2ccae8;
}

.header.dot.overHamburger {
  position: absolute;
  top: 6px;
  left: -5px;
  background: #ff5b33;
  height: 8px;
  width: 8px;
}

.header.hamburger {
  position: relative;
}

.justify_between {
  justify-content: space-between;
}

.gearbox_width {
  width: 20px;
}

.nav-headerMain {
  position: absolute;
  background-color: transparent;
  top: 15;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
}

.nav-headerTitle {
  padding: 20px;
  font-size: 25px;

  font-weight: bold;
  color: white;
}

.nav-headerMenu {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-left: 49px;
}

.nav-headerMenus {
  color: white;
  font-size: 20px;
  display: flex;
  padding: 20px;
  align-items: center;
  margin-top: -35px;
  justify-content: space-between;
}

.nav-Margin-left25px {
  margin-left: 25px;
}

.nav-img-widthHeight {
  margin-left: 10px;
  width: 25px;
  height: 25px;
}

.nav-mobile_menu {
  display: none;
}

.nav-signup_text {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-right: 20px;
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  .nav-mobile_menu {
    display: block;
    width: 29.25px;
    height: 19.5px;
    top: 9.75px;
    left: 4.88px;
    left: 12.5%;
    right: 12.5%;
    top: 25%;
    bottom: 25%;
    margin-left: 22.87px;
    margin-right: 21.88px;
  }
}