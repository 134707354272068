.cstmpwd {
    border-radius: 10px;
    height: 46px;
    border: 1px solid #858585;
    background: #fff;
    font-size: 14px;
    font-family: "Poppins";
    color: #000;
    width: 100%;
    padding: 0px 20px;
    &::placeholder {
      font-size: 14px;
      font-family: "Poppins";
      color: #858585;
      font-style: normal;
      padding: 0;
    }
  }