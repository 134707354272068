.rating-images {
  display: flex;
  justify-content: space-evenly;
  margin: 32px 0 50px 0;
}

.selected-rating {
  background-color: #49c6e0 !important;
}

.rating-border {
  border-radius: 50%;
  background-color: #a6f0ff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 62px;
}

.subheading {
  text-align: start;
  margin: 32px 0 0 0;
  font-size: 16px;
}

.rating-img-ctn {
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  width: 62px;
  cursor: pointer;
}
.rating-textarea {
  border-radius: 16px;
  border: 1px solid #d8d8d8;
  color: #b5b5b5;
  padding: 18px 14px 0 14px;
  height: 165px;
  width: 90% !important;

  width: 100%;
  font-size: 16px;
  font-style: italic;
  font-family: Poppins;
  color: #000000;
}
.rating-container span {
  padding: 0 0 0 0;
}

.confirm-rate {
  width: 100%;
  height: 65px;
  background-color: #2ccae8;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 15px;
  margin: 32px 0 0 0;
}
